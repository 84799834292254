import { ArdoqId } from '@ardoq/api-types';
import {
  GraphModelShape,
  LinkedComponent,
  ReferenceDescriptor,
  SimpleReferenceModel,
  GlobalReferenceTypeId,
} from '@ardoq/data-model';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';

export const referenceDescriptorsToGraphData = (
  referenceDescriptors: ReferenceDescriptor[]
): GraphModelShape => {
  const sourceMap = new Map<ArdoqId, LinkedComponent[]>();
  const targetMap = new Map<ArdoqId, LinkedComponent[]>();
  const referenceTypeMap = new Map<string, GlobalReferenceTypeId[]>();
  const referenceMap = new Map<ArdoqId, SimpleReferenceModel>();

  referenceDescriptors.forEach(
    ({
      referenceId,
      sourceId,
      targetId,
      referenceTypeId,
      referenceTypeName,
    }: ReferenceDescriptor) => {
      referenceMap.set(referenceId, { sourceId, targetId });

      if (referenceTypeId) {
        let typeIds = referenceTypeMap.get(referenceTypeName);
        if (!typeIds) {
          typeIds = [];
          referenceTypeMap.set(referenceTypeName, typeIds);
        }
        if (!typeIds.includes(referenceTypeId)) {
          typeIds.push(referenceTypeId);
        }
      }
      let targetIds = sourceMap.get(sourceId);
      if (!targetIds) {
        targetIds = [];
        sourceMap.set(sourceId, targetIds);
      }
      targetIds.push({ componentId: targetId, referenceId });
      let sourceIds = targetMap.get(targetId);
      if (!sourceIds) {
        sourceIds = [];
        targetMap.set(targetId, sourceIds);
      }
      sourceIds.push({ componentId: sourceId, referenceId });
    }
  );

  const referenceTypes = Array.from(referenceTypeMap)
    .map(([name, typeIds]) => ({ name, typeIds }))
    .sort((a, b) => localeCompare(a.name, b.name, getCurrentLocale()));

  sourceMap.forEach(list => Object.freeze(list));
  targetMap.forEach(list => Object.freeze(list));
  referenceMap.forEach(list => Object.freeze(list));

  return { sourceMap, targetMap, referenceMap, referenceTypes };
};
