import { FieldBackboneModel } from 'aqTypes';
import type { Fields } from 'collections/fields';

type FieldCache = Map<string, FieldBackboneModel[]>;

const addToCache = (
  cache: FieldCache,
  field: FieldBackboneModel,
  typeId = ''
) => {
  const cacheKey = `${field.get('model')}-${typeId}`;
  const currentCache = cache.get(cacheKey) || [];
  cache.set(cacheKey, [...currentCache, field]);
};

/*
 * This class maintains a cache for retrieving fields by comp/ref type & model
 *
 * The fields are sorted by their order.
 */
class FieldLookupCache {
  private collection: Fields;

  private fieldsByComponentCache: FieldCache = new Map();
  private fieldsByReferenceCache: FieldCache = new Map();
  private dirtyCache = true;

  constructor(collection: Fields) {
    this.collection = collection;
    this.collection.on(
      'sync change:componentType change:referenceType change:globalref change:global add remove',
      () => {
        this.dirtyCache = true;
      }
    );
  }

  private addToComponentCache(field: FieldBackboneModel, typeId = '') {
    addToCache(this.fieldsByComponentCache, field, typeId);
  }

  private addToReferenceCache(field: FieldBackboneModel, typeId = '') {
    addToCache(this.fieldsByReferenceCache, field, typeId);
  }

  /**
   * Should ensure that the cache is ordered by field _order
   */
  private rebuildCache() {
    this.fieldsByComponentCache = new Map();
    this.fieldsByReferenceCache = new Map();

    this.collection.forEach(field => {
      const model = field.getModel();
      const compTypesInModel = model ? Object.keys(model.getAllTypes()) : [];
      const refTypesInModel = model
        ? Object.keys(model.getReferenceTypes())
        : [];
      // For comps
      if (field.get('global')) {
        compTypesInModel.forEach(typeId =>
          this.addToComponentCache(field, typeId)
        );
      } else {
        field.getComponentTypes().forEach((typeId: string) => {
          this.addToComponentCache(field, typeId);
        });
      }
      // For refs
      if (field.get('globalref')) {
        refTypesInModel.forEach(typeId =>
          this.addToReferenceCache(field, typeId)
        );
      } else {
        field.getReferenceTypes().forEach((typeId: string) => {
          this.addToReferenceCache(field, typeId);
        });
      }
    });
    this.dirtyCache = false;
  }

  private getFromCache(modelId: string, typeId: string, isComponent: boolean) {
    if (this.dirtyCache) {
      this.rebuildCache();
    }
    const cache = isComponent
      ? this.fieldsByComponentCache
      : this.fieldsByReferenceCache;
    const cacheKey = `${modelId}-${typeId}`;
    return [...(cache.get(cacheKey) || [])];
  }

  invalidateCache() {
    this.dirtyCache = true;
  }

  getFieldsByComponentType(modelId: string, typeId: string) {
    return this.getFromCache(modelId, typeId, true);
  }

  getFieldsByReferenceType(modelId: string, typeId: string) {
    return this.getFromCache(modelId, typeId, false);
  }
}

export default FieldLookupCache;
