import _ from 'lodash';
import Fields from './fields';
import { APIFieldType, FilterInfoOperator } from '@ardoq/api-types';
import { fieldTypeFromFieldName } from 'models/utils/fieldTypeFromFieldName';
import {
  CommonDefaultFilterKeys,
  ComponentDefaultFilterKeys,
  ReferenceDefaultFilterKeys,
  comparators as commonComparators,
  getIdMatchComparator,
} from '@ardoq/filter-interface';
import type { AttributeComparator } from '@ardoq/filter-interface';
import Context from '../context';

const getContextComponentId = () => {
  return Context.componentId() || undefined;
};

const comparators: Record<string, AttributeComparator> = {
  ...commonComparators,
  idMatch: getIdMatchComparator(getContextComponentId),
};

// Defines the default and custom comparator sets
// for different types of attributes
const COMPARATOR_SETS = {
  DEFAULT: {
    comparators: [
      FilterInfoOperator.EQUALS,
      FilterInfoOperator.CONTAINS,
      FilterInfoOperator.GREATER_THAN,
      FilterInfoOperator.LESS_THAN,
    ],
  },
  custom: {
    BOOLEAN: {
      comparators: [FilterInfoOperator.BOOLEAN],
      fieldTypes: [APIFieldType.CHECKBOX],
    },
    DATE: {
      comparators: [
        FilterInfoOperator.DATE_MATCH,
        FilterInfoOperator.DATE_LATER,
        FilterInfoOperator.DATE_EARLIER,
      ],
      fieldTypes: [APIFieldType.DATE_ONLY, APIFieldType.DATE_TIME],
      componentAttributes: [
        CommonDefaultFilterKeys.LAST_UPDATED,
        CommonDefaultFilterKeys.CREATED,
      ],
    },
    ID: {
      comparators: [FilterInfoOperator.ID_MATCH],
      componentAttributes: [
        CommonDefaultFilterKeys.TYPE,
        CommonDefaultFilterKeys.ID,
        ComponentDefaultFilterKeys.PARENT,
        ReferenceDefaultFilterKeys.TARGET,
        ReferenceDefaultFilterKeys.SOURCE,
        CommonDefaultFilterKeys.ROOT_WORKSPACE,
        ReferenceDefaultFilterKeys.TARGET_WORKSPACE,
      ],
    },
    DATERANGE: {
      comparators: [
        FilterInfoOperator.DATE_RANGE_CONTAINS,
        FilterInfoOperator.DATE_RANGE_DOES_NOT_CONTAIN,
        FilterInfoOperator.DATE_RANGE_BEFORE,
        FilterInfoOperator.DATE_RANGE_AFTER,
      ],
      fieldTypes: [APIFieldType.DATE_ONLY_RANGE, APIFieldType.DATE_TIME_RANGE],
    },
    LIST: {
      comparators: [FilterInfoOperator.EQUALS, FilterInfoOperator.CONTAINS],
      fieldTypes: [APIFieldType.LIST, APIFieldType.SELECT_MULTIPLE_LIST],
    },
  },
};

export const AttributeComparators = {
  getAllComparators: function () {
    return comparators;
  },
  getComparatorSets: function () {
    return COMPARATOR_SETS;
  },
  getDefaultComparator: function (attribute: string) {
    if (!attribute) {
      return COMPARATOR_SETS.DEFAULT.comparators[0];
    }
    return this.getAvailableComparators(attribute)[0];
  },
  getAvailableComparators: function (attribute: string) {
    const fieldType = fieldTypeFromFieldName(attribute, Fields.collection);
    let comparatorSet;
    // Look through to see if there's a custom comparator set
    // otherwise set it to default
    comparatorSet = _.find(
      COMPARATOR_SETS.custom,
      (set: {
        comparators: string[];
        fieldTypes?: string[];
        componentAttributes?: string[];
      }) =>
        Boolean(
          fieldType
            ? set.fieldTypes && set.fieldTypes.includes(fieldType)
            : set.componentAttributes &&
                set.componentAttributes.includes(attribute)
        )
    );
    comparatorSet = comparatorSet || COMPARATOR_SETS.DEFAULT;

    return _.map(comparatorSet.comparators, (comparatorId: string) =>
      this.getById(comparatorId)
    );
  },
  getById: (id: string) => comparators[id],
  // Necessary when we have to infer the comparators from the URL (no comparatorID)
  getByOperator: function (operator: string, attribute: string) {
    return _.find(this.getAvailableComparators(attribute), function (c) {
      return c.operator === operator;
    });
  },
};
