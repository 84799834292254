import { ComponentBackboneModel, ComponentHierarchyInterface } from 'aqTypes';
import { ArdoqId } from '@ardoq/api-types';
import { CollectionView } from 'collections/consts';
import type { Components } from 'collections/components';

type WorkspaceHierarchy = Map<ArdoqId, ComponentBackboneModel[]>;
type Hierarchy = Map<ArdoqId, WorkspaceHierarchy>;

/*
 * This class maintains the hierarchy of component parent child relations
 * for the components in a component collection. The hierarchy is stored per
 * workspace.
 *
 * It should be used when getting children for a component.
 */
class ComponentHierarchy implements ComponentHierarchyInterface {
  private hierarchy: Hierarchy;
  private collection: Components;
  constructor(collection: Components) {
    this.hierarchy = new Map<ArdoqId, WorkspaceHierarchy>();
    this.collection = collection;
    // for the "change:parent" event, see "handleChangeParent" in the component model
    // On batch remove we explicitly update the workspace hierarchy
    this.collection.on('add remove', (component: ComponentBackboneModel) =>
      this.clearWorkspaceHierarchy(component.get('rootWorkspace'))
    );
  }
  getChildren = (
    component: ComponentBackboneModel,
    nodeCollectionView = CollectionView.DEFAULT_VIEW
  ): ComponentBackboneModel[] => {
    const rootWorkspaceId = component.get('rootWorkspace');
    const workspaceHierarchy = this.getWorkspaceHierarchy(
      rootWorkspaceId,
      nodeCollectionView
    );
    if (workspaceHierarchy.has(component.id)) {
      return workspaceHierarchy.get(component.id)!.slice();
    }
    return [];
  };

  private getWorkspaceHierarchy = (
    rootWorkspaceId: ArdoqId,
    nodeCollectionView = CollectionView.DEFAULT_VIEW
  ): WorkspaceHierarchy => {
    const key = nodeCollectionView
      ? `${rootWorkspaceId}-${nodeCollectionView}`
      : rootWorkspaceId;
    if (!this.hierarchy.has(key)) {
      this.buildWorkspaceHierarchy(rootWorkspaceId, nodeCollectionView, key);
    }
    return this.hierarchy.get(key)!;
  };
  private buildWorkspaceHierarchy = (
    rootWorkspaceId: ArdoqId,
    nodeCollectionView = CollectionView.DEFAULT_VIEW,
    key: string
  ) => {
    const collection =
      this.collection.views.get(nodeCollectionView) || this.collection;
    const workspaceComponents = collection.getWorkspaceComponents(
      null,
      rootWorkspaceId
    );
    const workspaceHierarchy: WorkspaceHierarchy =
      this.getHierarchyFromComponents(workspaceComponents);
    this.hierarchy.set(key, workspaceHierarchy);
  };
  private getHierarchyFromComponents = (
    components: ComponentBackboneModel[]
  ): WorkspaceHierarchy =>
    components.reduce((acc, nextComponent) => {
      const parent = nextComponent.get('parent');
      if (!parent) {
        return acc;
      }
      if (acc.has(parent)) {
        acc.set(parent, [...acc.get(parent), nextComponent]);
      } else {
        acc.set(parent, [nextComponent]);
      }
      return acc;
    }, new Map());
  clearWorkspaceHierarchy = (workspaceId: ArdoqId) => {
    Array.from(this.hierarchy.keys())
      .filter(key => key && key.startsWith(workspaceId))
      .forEach(key => this.hierarchy.delete(key));
  };
  clearHierarchyForComponents = (components: ComponentBackboneModel[]) => {
    new Set(
      components.map(component => component.get('rootWorkspace'))
    ).forEach(workspaceId => this.clearWorkspaceHierarchy(workspaceId));
  };
  clearAllHierarchies() {
    this.hierarchy.clear();
  }
}

export default ComponentHierarchy;
