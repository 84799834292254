import { ViewIds } from '@ardoq/api-types';
import type { ObservableState } from '@ardoq/rxbeach';
import type { Attributes } from 'react';
import type { Observable } from 'rxjs';

export enum BooleanOrAuto {
  True = 1,
  False = 0,
  Auto = -1,
}

export interface EmbeddableViewConfig {
  readonly showLeftSettingsSection?: boolean;
  readonly showRightSettingsSection?: boolean;
  readonly enableContextChanges?: boolean;
  readonly enableRightClick?: boolean;
}
export type InstantiableReactViewProps = {
  viewId: ViewIds;
  embeddableViewConfig?: EmbeddableViewConfig;
} & Attributes;

export type IconStyle = {
  color: string;
};

export type ExtractStreamShape<Type> = Type extends
  | Observable<infer X>
  | ObservableState<infer X>
  ? X
  : never;
