import { persistentReducedStream } from '@ardoq/rxbeach';
import { OrgUsersStreamShape } from './types';
import { reducers } from './reducers';

export const defaultState: OrgUsersStreamShape = {
  users: [],
  byId: {},
};

/**
 * Stream that contains all users in the organization (including contributors).
 */
export const orgUsers$ = persistentReducedStream(
  'orgUsers$',
  defaultState,
  reducers
);
