export enum ExcludedSet {
  DEFAULT_SET_NAME = 'default',
  SCENARIO_RELATED_SET = 'scenario-related',
  PLACEHOLDER_SET = 'placeholder-set',
  COLLAPSED_SET = 'collapsed-set',
  INTERNAL = 'internal',
}

export enum CollectionView {
  DEFAULT_VIEW = 'default',
  BASE_VIEW = 'base-view',
  WITH_PLACEHOLDERS = 'with-placeholders',
}

export const EVENT_TAG_ADDED = 'addedTag'; // should pass comp and tag
export const EVENT_TAG_REMOVED = 'removedTag'; // should pass comp and tag
