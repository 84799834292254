import { APIFieldType } from '@ardoq/api-types';
import {
  dateRangeOperations,
  getDateRangeType,
  getStartDateName,
} from '@ardoq/date-range';
import { Fields } from 'collections/fields';

export const fieldTypeFromFieldName = (
  fieldName: string,
  fields: Fields
): APIFieldType | null => {
  if (!fieldName) return null;

  if (dateRangeOperations.isCombinedDateRangeFieldNames(fieldName)) {
    const field = fields.getByName(getStartDateName(fieldName), {
      acrossWorkspaces: true,
    });

    return field ? getDateRangeType(field.getType()) : null;
  }

  const field = fields.getByName(fieldName, { acrossWorkspaces: true });

  return field ? field.getType() : null;
};
