import Workspace from 'models/workspace';
import { BasicModel, Workspace as WorkspaceBackboneModel } from 'aqTypes';
import { syncConflictAlert } from '@ardoq/modal';
import { trackEvent } from 'tracking/tracking';
import { clone, each, isEqual, isFunction } from 'lodash';

const fetchWorkspace = (selectedWorkspace: WorkspaceBackboneModel) => {
  if (selectedWorkspace && selectedWorkspace.id) {
    const tempWorkspace = new Workspace.model({
      _id: selectedWorkspace.id,
    });
    tempWorkspace.fetch({
      success: function () {
        diffWorkspace(selectedWorkspace, tempWorkspace);
      },
    });
  }
};

const diffWorkspace = (
  selectedWorkspace: WorkspaceBackboneModel,
  tempWorkspace: BasicModel
) => {
  if (
    selectedWorkspace.attributes._version !==
      tempWorkspace.attributes._version &&
    !isEqual(selectedWorkspace.attributes, tempWorkspace.attributes)
  ) {
    const previousAttributes = clone(selectedWorkspace.attributes);
    selectedWorkspace.attributes = tempWorkspace.attributes;
    doVisualDiffIfNeeded(selectedWorkspace, previousAttributes);
    selectedWorkspace.trigger('sync merged', selectedWorkspace);
  }
};

export const mergeObject = (model: BasicModel) => {
  if (model.id && model.mustBeSaved) {
    if (
      (isFunction(model.urlRoot) ? model.urlRoot() : model.urlRoot).indexOf(
        '/workspace'
      ) > -1
    ) {
      return fetchWorkspace(model as WorkspaceBackboneModel);
    }
    const previousAttributes = clone(model.attributes);
    model.fetch({
      success: function () {
        if (previousAttributes._version !== model.attributes._version) {
          // Making sure that description changed token follows model change.
          doVisualDiffIfNeeded(model, previousAttributes);
          model.trigger('merged', model);
        }
      },
    });
  }
};

const doVisualDiffIfNeeded = (
  model: BasicModel,
  previousAttributes: Record<string, any>
) => {
  let different = false;
  const modelHasChanges = model.mustBeSaved;
  each(previousAttributes, function (val, key) {
    if (
      key !== '_version' &&
      key !== 'fields' &&
      key !== 'references' &&
      key !== 'components' &&
      key !== 'children' &&
      key !== 'lastUpdated' &&
      val !== model.attributes[key]
    ) {
      different = true;
    }
  });
  if (modelHasChanges && different) {
    syncConflictAlert({
      entityType: model.get('ardoq')['entity-type'],
      entityId: model.get('_id'),
      trackEvent: params => trackEvent('Entity sync error detected', params),
    });
    return true;
  }
  return false;
};
