import { usersOperations } from '@ardoq/core';
import { orgUsersInterface } from './orgUsers/orgUsersInterface';

/**
 * A temporary workaround for the fact that the scopeData does not account
 * for users that are referenced in custom fields
 */
export const formatUserField = (val: any) => {
  if (typeof val !== 'string') return null;
  const user = orgUsersInterface.getUserById(val);
  return user ? usersOperations.getUserDisplayText(user) : null;
};
