import { HTMLAttributes } from 'react';
import { ExistentMetaInfoViewStatus } from './types';
import viewStatusLabels from './viewStatusLabels';
import viewStatusBrands from './viewStatusBrands';
import { Tag } from '@ardoq/status-ui';
import styled from 'styled-components';

type ViewStatusTextBadgeProperties = {
  viewStatus: ExistentMetaInfoViewStatus;
} & HTMLAttributes<HTMLSpanElement>;

const TagWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const ViewStatusTextBadge = ({
  viewStatus,
  ...props
}: ViewStatusTextBadgeProperties) => (
  <TagWrapper {...props}>
    <Tag
      statusType={viewStatusBrands[viewStatus]}
      label={viewStatusLabels[viewStatus]}
    />
  </TagWrapper>
);

export default ViewStatusTextBadge;
