import { ArdoqId } from '@ardoq/api-types';
import {
  AttachmentWithContext,
  DocumentArchiveContext,
} from '../../components/DocumentBrowser/types';

const isOrgAttachment = (attachment: AttachmentWithContext) =>
  attachment.context === DocumentArchiveContext.ORG ||
  !attachment.rootWorkspace;

const isWsAttachment = (
  { rootWorkspace }: AttachmentWithContext,
  workspaceId: ArdoqId
) => rootWorkspace === workspaceId;

const isOrgOrWorkspaceAttachment = (
  attachment: AttachmentWithContext,
  workspaceId: ArdoqId
) => isOrgAttachment(attachment) || isWsAttachment(attachment, workspaceId);

const toSelectOption = (attachment: AttachmentWithContext) => ({
  value: attachment._id,
  label: attachment.filename,
});

export default {
  isOrgAttachment,
  isWsAttachment,
  isOrgOrWorkspaceAttachment,
  toSelectOption,
};
