import { UserResourcePermissionsRecord } from '@ardoq/access-control';
import reducers from './reducers';
import { persistentReducedStream } from '@ardoq/rxbeach';

export const currentUserPermissions$ =
  persistentReducedStream<UserResourcePermissionsRecord>(
    'currentUserPermissions$',
    {},
    reducers
  );
