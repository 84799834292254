import BasicModel from 'models/basicmodel';
import { CollectionView } from 'collections/consts';
import type { ComponentHierarchyInterface, NodeBackboneModel } from 'aqTypes';
import Backbone from 'backbone';

type CollectionWithHierarchy = Backbone.Collection<Backbone.Model> & {
  hierarchy: ComponentHierarchyInterface;
};

const Node = BasicModel.extend({
  idAttribute: '_id',
  defaults: {
    _id: null,
    parent: null,
  },
  getChildrenAsObjects: function (
    containingCollection: Backbone.Collection<Backbone.Model>,
    nodeCollectionView = CollectionView.DEFAULT_VIEW
  ) {
    if (
      containingCollection &&
      (containingCollection as CollectionWithHierarchy).hierarchy
    ) {
      return (
        containingCollection as CollectionWithHierarchy
      ).hierarchy.getChildren(this, nodeCollectionView);
    }
    return [];
  },
  addChild: function (this: NodeBackboneModel, child: NodeBackboneModel) {
    if (child === this) {
      throw new Error('Cannot add my self as child.');
    } else if (child.get('_id') === this.get('parent')) {
      throw new Error('Cannot add my parent as a child.');
    }
    if (child.isNew()) {
      return;
    }
    child.setParent(this);
  },
  getParentObject: function (
    containingCollection: Backbone.Collection<Backbone.Model>
  ) {
    const parentId = this.get('parent');
    if (parentId !== undefined && containingCollection) {
      return containingCollection.get(parentId);
    }
    return null;
  },
  setParent: function (this: NodeBackboneModel, parent: NodeBackboneModel) {
    if (typeof parent === 'string') {
      if (parent === this.id) {
        throw new Error('Parent cannot be my self');
      }
      if (parent === '') {
        this.set('parent', null);
      } else {
        this.set('parent', parent);
      }
    } else if (parent === this) {
      throw new Error('Parent cannot be my self');
    }
    if (parent === null || parent === undefined) {
      this.set('parent', null);
    } else {
      this.set('parent', parent.getId());
    }
  },
});
function createNode(props: Record<string, any>) {
  return new Node(props);
}

export default {
  model: Node,
  create: createNode,
};
