import { updateEnhancedScopeDiffData } from 'scope/actions';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { PayloadUpdateEnhancedScopeDiffData } from './types';
import { returnPayload } from '@ardoq/common-helpers';

const handleUpdateEnhancedScopeDiffData = reducer(
  updateEnhancedScopeDiffData,
  returnPayload<PayloadUpdateEnhancedScopeDiffData>
);

const reducers = [handleUpdateEnhancedScopeDiffData];
const defaultState: PayloadUpdateEnhancedScopeDiffData = {
  branch: null,
  main: null,
  branchOff: null,
  diffData: null,
};

const enhancedScopeDiffData$ = persistentReducedStream(
  'enhancedScopeDiffData$',
  defaultState,
  reducers
);
export default enhancedScopeDiffData$;
