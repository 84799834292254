import { APIEntityType } from '@ardoq/api-types';
import Components from 'collections/components';
import References from 'collections/references';
import Workspaces from 'collections/workspaces';
import Models from 'collections/models';
import Tags from 'collections/tags';
import Fields from 'collections/fields';
import { Collection } from './types';

const collectionMap = new Map<APIEntityType, Collection<APIEntityType>>([
  [APIEntityType.COMPONENT, Components.collection],
  [APIEntityType.REFERENCE, References.collection],
  [APIEntityType.WORKSPACE, Workspaces.collection],
  [APIEntityType.MODEL, Models.collection],
  [APIEntityType.TAG, Tags.collection],
  [APIEntityType.FIELD, Fields.collection],
]);

export const getCollectionForEntityType = <T extends APIEntityType>(
  entityType: T
): Collection<T> => {
  return collectionMap.has(entityType)
    ? (collectionMap.get(entityType) as Collection<T>)
    : (Components.collection as Collection<T>);
};
