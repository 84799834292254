export interface PayloadShowAppModule {
  readonly selectedModule: AppModules;
  readonly options?: {
    readonly forceLandingPage?: boolean;
  };
}

export enum AppModules {
  DASHBOARDS = 'dashboards',
  HOME = 'home',
  INTEGRATIONS = 'integrations',
  SEARCH = 'search',
  PRESENTATIONS = 'presentations',
  SURVEY_ADMIN = 'survey_admin',
  WORKSPACES = 'workspaces',
  METAMODEL = 'metamodel',
  ORGANIZATION_METAMODEL = 'organization-metamodel',
  BROADCASTS = 'broadcasts',
  SCENARIO_MERGE = 'scenario-merge',
  VIEWPOINTS = 'viewpoints',
  REPORTS = 'report',
  MANAGE_ORGANIZATION = 'manage-organization',
  ACCESS_CONTROL = 'access-control',
  USER_SETTINGS = 'account-settings',
  TRAVERSALS = 'traversals',
  SUBDIVISION = 'division',
  AUDIT_LOG = 'audit-log',
  USE_CASES = 'use-cases',
  COMPONENT_OVERVIEW_PAGE = 'component-overview-page',
  ANALYTICS = 'analytics',
  ASSETS = 'assets',
  INVENTORY = 'inventory',
  EXTERNAL_DOCUMENT = 'external-document',
}
