import { reducer, streamReducer } from '@ardoq/rxbeach';
import {
  createZone,
  createZoneFailure,
  createZoneSuccessfully,
  deleteZone,
  deleteZoneFailure,
  deleteZoneSuccessfully,
  fetchAllZones,
  fetchedZonesSuccessfully,
  fetchingZonesFailure,
  syncGridEditorSubdivisions,
  updateZone,
  updateZoneFailure,
  updateZoneSuccessfully,
  fetchedSubdivisionsSuccessfully,
  saveSubdivisionDetailsSuccessfully,
  deleteSubdivisionSuccessfully,
  fetchAllSubdivisions,
} from './actions';
import { ApiZonesFailurePayload, SubdivisionsStreamShape } from './types';
import { ArdoqId, Subdivision, Zone } from '@ardoq/api-types';
import {
  subdivisionsOperations,
  subdivisionsZonesOperations,
} from '@ardoq/subdivisions';
import { subdivisionsZoneWebsocketEvents$ } from './subdivisionsZoneWebsocketEvents$';
import { ArdoqEvent, isResourceUpdate } from 'sync/types';

const fetchAllZonesReducer = (
  state: SubdivisionsStreamShape
): SubdivisionsStreamShape => {
  return {
    ...state,
    zonesContext: {
      ...state.zonesContext,
      isFetching: true,
      fetchingError: null,
    },
  };
};

const fetchedZonesSuccessfullyReducer = (
  state: SubdivisionsStreamShape,
  payload: Zone[]
): SubdivisionsStreamShape => ({
  ...state,
  zonesContext: subdivisionsZonesOperations.createSubdivisionWithZonesContext(
    subdivisionsOperations.getEmptySubdivisionsContext(),
    payload
  ).zonesContext,
  isFetching: false,
  fetchingError: null,
});

const fetchingZonesFailureReducer = (
  state: SubdivisionsStreamShape,
  payload: ApiZonesFailurePayload
): SubdivisionsStreamShape => {
  return {
    ...state,
    zonesContext: {
      ...state.zonesContext,
      isFetching: false,
      fetchingError: payload,
    },
  };
};

const createZoneReducer = (
  state: SubdivisionsStreamShape
): SubdivisionsStreamShape => {
  return {
    ...state,
    isSubmitting: true,
    submittingError: null,
  };
};

const createZoneSuccessfullyReducer = (
  state: SubdivisionsStreamShape,
  payload: Zone
): SubdivisionsStreamShape => {
  return {
    ...state,
    zonesContext: {
      zones: { ...state.zonesContext.zones, [payload._id]: payload },
      sortedIds: [
        ...subdivisionsZonesOperations.getSortedZonesIds(state),
        payload._id,
      ],
      fetchingError: null,
      isFetching: false,
    },
    isSubmitting: false,
    submittingError: null,
  };
};

const createZoneFailureReducer = (
  state: SubdivisionsStreamShape,
  payload: ApiZonesFailurePayload
): SubdivisionsStreamShape => {
  return {
    ...state,
    isSubmitting: false,
    submittingError: payload,
  };
};

const updateZoneReducer = (
  state: SubdivisionsStreamShape
): SubdivisionsStreamShape => {
  return {
    ...state,
    isSubmitting: true,
    submittingError: null,
  };
};

const updateZoneSuccessfullyReducer = (
  state: SubdivisionsStreamShape,
  payload: Zone
): SubdivisionsStreamShape => {
  return {
    ...state,
    zonesContext: {
      zones: { ...state.zonesContext.zones, [payload._id]: payload },
      sortedIds: subdivisionsZonesOperations.getSortedZonesIds(state),
      fetchingError: null,
      isFetching: false,
    },
    isSubmitting: false,
    submittingError: null,
  };
};

const updateZoneFailureReducer = (
  state: SubdivisionsStreamShape,
  payload: ApiZonesFailurePayload
): SubdivisionsStreamShape => {
  return {
    ...state,
    isSubmitting: false,
    submittingError: payload,
  };
};

const deleteZoneReducer = (
  state: SubdivisionsStreamShape
): SubdivisionsStreamShape => {
  return {
    ...state,
    isSubmitting: true,
    submittingError: null,
  };
};

const deleteZoneSuccessfullyReducer = (
  state: SubdivisionsStreamShape,
  zoneId: ArdoqId
): SubdivisionsStreamShape => {
  const newZoneById = { ...state.zonesContext.zones };
  delete newZoneById[zoneId];

  return {
    ...state,
    zonesContext: {
      zones: newZoneById,
      sortedIds: subdivisionsZonesOperations
        .getSortedZonesIds(state)
        .filter(id => id !== zoneId),
      fetchingError: null,
      isFetching: false,
    },
    isSubmitting: false,
    submittingError: null,
  };
};

const deleteZoneFailureReducer = (
  state: SubdivisionsStreamShape,
  payload: ApiZonesFailurePayload
): SubdivisionsStreamShape => {
  return {
    ...state,
    isSubmitting: false,
    submittingError: payload,
  };
};

// -------------------------------------------------------
// -------------    Subdivisions Reducers    -------------
// -------------------------------------------------------

/**
 * Syncs the zones in the grid editor with the zones in the main app.
 *
 * This is needed because the `fetchedZonesSuccessfullyReducer` takes
 * zones shape returned form the API as payload.
 *
 * But the Bridge will emit the current stream state as payload.
 */
const syncGridEditorSubdivisionsReducer = (
  _: SubdivisionsStreamShape,
  payload: SubdivisionsStreamShape
): SubdivisionsStreamShape => payload;

const fetchAllSubdivisionsReducer = (
  state: SubdivisionsStreamShape
): SubdivisionsStreamShape => {
  return {
    ...state,
    isFetching: true,
    fetchingError: null,
  };
};

const fetchedSubdivisionsSuccessfullyReducer = (
  state: SubdivisionsStreamShape,
  payload: Subdivision[]
): SubdivisionsStreamShape => {
  return {
    ...state,
    ...subdivisionsOperations.createSubdivisionsContext(
      payload,
      state.zonesContext
    ),
    isFetching: false,
    fetchingError: null,
  };
};

const saveSubdivisionSuccessfullyReducer = (
  state: SubdivisionsStreamShape,
  payload: Subdivision
): SubdivisionsStreamShape => {
  const isNewSubdivision = !subdivisionsOperations.getSubdivisionById(
    state,
    payload._id
  );
  if (isNewSubdivision) {
    return {
      ...state,
      subdivisions: {
        ...state.subdivisions,
        [payload._id]: payload,
      },
      sortedIds: [...state.sortedIds, payload._id],
      isSubmitting: false,
      submittingError: null,
    };
  }
  return {
    ...state,
    subdivisions: {
      ...state.subdivisions,
      [payload._id]: payload,
    },
    isSubmitting: false,
    submittingError: null,
  };
};

const deleteSubdivisionSuccessfullyReducer = (
  state: SubdivisionsStreamShape,
  subdivisionId: ArdoqId
): SubdivisionsStreamShape => {
  const newSubdivisions = { ...state.subdivisions };
  delete newSubdivisions[subdivisionId];
  return {
    ...state,
    subdivisions: newSubdivisions,
    sortedIds: state.sortedIds.filter(id => id !== subdivisionId),
    isSubmitting: false,
    submittingError: null,
  };
};

const updateFromWebsocketZoneEventsReducer = (
  state: SubdivisionsStreamShape,
  event: ArdoqEvent<Zone>
): SubdivisionsStreamShape => {
  if (isResourceUpdate(event)) {
    return {
      ...state,
      ...subdivisionsZonesOperations.updateOrCreateZone(state, event.data),
    };
  }
  return state;
};

export const subdivisionReducers = [
  reducer<SubdivisionsStreamShape>(fetchAllZones, fetchAllZonesReducer),
  reducer<SubdivisionsStreamShape, Zone[]>(
    fetchedZonesSuccessfully,
    fetchedZonesSuccessfullyReducer
  ),
  reducer<SubdivisionsStreamShape, ApiZonesFailurePayload>(
    fetchingZonesFailure,
    fetchingZonesFailureReducer
  ),
  reducer<SubdivisionsStreamShape>(createZone, createZoneReducer),
  reducer<SubdivisionsStreamShape, Zone>(
    createZoneSuccessfully,
    createZoneSuccessfullyReducer
  ),
  reducer<SubdivisionsStreamShape, ApiZonesFailurePayload>(
    createZoneFailure,
    createZoneFailureReducer
  ),
  reducer<SubdivisionsStreamShape>(updateZone, updateZoneReducer),
  reducer<SubdivisionsStreamShape, Zone>(
    updateZoneSuccessfully,
    updateZoneSuccessfullyReducer
  ),
  reducer<SubdivisionsStreamShape, ApiZonesFailurePayload>(
    updateZoneFailure,
    updateZoneFailureReducer
  ),
  reducer<SubdivisionsStreamShape>(deleteZone, deleteZoneReducer),
  reducer<SubdivisionsStreamShape, ArdoqId>(
    deleteZoneSuccessfully,
    deleteZoneSuccessfullyReducer
  ),
  reducer<SubdivisionsStreamShape, ApiZonesFailurePayload>(
    deleteZoneFailure,
    deleteZoneFailureReducer
  ),
  reducer<SubdivisionsStreamShape, SubdivisionsStreamShape>(
    syncGridEditorSubdivisions,
    syncGridEditorSubdivisionsReducer
  ),

  // --------------- Subdivisions Reducers ----------------
  reducer<SubdivisionsStreamShape>(
    fetchAllSubdivisions,
    fetchAllSubdivisionsReducer
  ),
  reducer<SubdivisionsStreamShape, Subdivision[]>(
    fetchedSubdivisionsSuccessfully,
    fetchedSubdivisionsSuccessfullyReducer
  ),
  reducer<SubdivisionsStreamShape, Subdivision>(
    saveSubdivisionDetailsSuccessfully,
    saveSubdivisionSuccessfullyReducer
  ),
  reducer<SubdivisionsStreamShape, ArdoqId>(
    deleteSubdivisionSuccessfully,
    deleteSubdivisionSuccessfullyReducer
  ),
  streamReducer<SubdivisionsStreamShape, ArdoqEvent<Zone>>(
    subdivisionsZoneWebsocketEvents$,
    updateFromWebsocketZoneEventsReducer
  ),
];
