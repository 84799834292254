import { ResourceType, Zone } from '@ardoq/api-types';
import { filter } from 'rxjs';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import { ArdoqEvent } from 'sync/types';
import { websocket$ } from 'sync/websocket$';

const isSubdivisionsZoneWebsocketEvent = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<Zone> => {
  return ardoqEventOperations.isOfResourceType(
    event,
    ResourceType.PERMISSION_ZONE
  );
};

export const subdivisionsZoneWebsocketEvents$ = websocket$.pipe(
  filter(event => isSubdivisionsZoneWebsocketEvent(event))
);
