import {
  APIEntityType,
  FilterTypes,
  FilterAttributes,
  LabelFormattingBaseAttributes,
} from '@ardoq/api-types';
import { exhaustiveCheck } from '@ardoq/common-helpers';
import {
  ComponentOrReferenceFormattingRule,
  FormattingRule,
  TagFormattingRule,
} from '@ardoq/perspectives';

export const formattingRulesToFormattingAttributes = (
  formattingRules: FormattingRule[]
): FilterAttributes[] =>
  formattingRules.map(formattingRule => {
    switch (formattingRule.type) {
      case APIEntityType.TAG:
        return toTagFormattingAttributes(formattingRule);
      case APIEntityType.COMPONENT:
        return toComponentFormattingAttributes(formattingRule);
      case APIEntityType.REFERENCE:
        return toReferenceFormattingAttributes(formattingRule);
      default:
        return exhaustiveCheck(formattingRule);
    }
  });

const toTagFormattingAttributes = (
  tagFormattingRule: TagFormattingRule
): FilterAttributes => {
  return {
    affectComponent: true,
    affectReference: true,
    type: FilterTypes.TAG,
    color: tagFormattingRule.color,
    isNegative: tagFormattingRule.isInverted,
    name: tagFormattingRule.value ?? undefined,
    value: tagFormattingRule.value,
  };
};

const toComponentFormattingAttributes = (
  componentFormattingRule: ComponentOrReferenceFormattingRule
): FilterAttributes => {
  return {
    affectComponent: true,
    affectReference: false,
    color: componentFormattingRule.color,
    isNegative: componentFormattingRule.isInverted,
    value: componentFormattingRule.value,
    name: componentFormattingRule.field,
    type: FilterTypes.ATTRIBUTE,
    comparator: componentFormattingRule.comparator,
  };
};

const toReferenceFormattingAttributes = (
  componentFormattingRule: ComponentOrReferenceFormattingRule
): FilterAttributes => {
  return {
    affectComponent: false,
    affectReference: true,
    color: componentFormattingRule.color,
    isNegative: componentFormattingRule.isInverted,
    value: componentFormattingRule.value,
    name: componentFormattingRule.field,
    type: FilterTypes.ATTRIBUTE,
    comparator: componentFormattingRule.comparator,
  };
};

export const getComponentLabelFormattingFilterAttributes = (
  componentLabelFormattingValue: string,
  includeTime: boolean | undefined
): LabelFormattingBaseAttributes & {
  type: FilterTypes.COMPONENT_LABEL;
  affectComponent: true;
  affectReference: false;
} => {
  return {
    affectComponent: true,
    affectReference: false,
    isNegative: false,
    name: '',
    type: FilterTypes.COMPONENT_LABEL,
    value: componentLabelFormattingValue,
    ...(includeTime ? { includeTime } : {}),
  };
};

export const getReferenceLabelFormattingFilterAttributes = (
  referenceLabelFormattingValue: string,
  includeTime: boolean | undefined
): LabelFormattingBaseAttributes & {
  type: FilterTypes.REFERENCE_LABEL;
  affectReference: true;
  affectComponent: false;
} => {
  return {
    affectComponent: false,
    affectReference: true,
    isNegative: false,
    name: '',
    type: FilterTypes.REFERENCE_LABEL,
    value: referenceLabelFormattingValue,
    ...(includeTime ? { includeTime } : {}),
  };
};
