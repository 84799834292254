import {
  actionCreator,
  persistentReducedStream,
  reducer,
  dispatchAction,
} from '@ardoq/rxbeach';
import { MetaInfo } from 'streams/views/mainContent/types';
import { applicationStarted } from 'streams/context/ContextActions';
import getMetaInfoTabs from './metaInfoTabs';
import { ViewIds } from '@ardoq/api-types';
import { subscribeToActionOnce } from 'streams/utils/streamUtils';

const setViews = actionCreator<MetaInfo[]>('[views] SET_AVAILABLE_VIEWS');

subscribeToActionOnce(applicationStarted, async () => {
  const views = Array.from(getMetaInfoTabs().values());
  dispatchAction(setViews(views));
});

export type AvailableViews = {
  views: MetaInfo[];
  byIds: Record<string, MetaInfo>;
  ids: ViewIds[];
};

const setViewsReducer = (_: AvailableViews, views: MetaInfo[]) => ({
  views,
  byIds: Object.fromEntries(views.map(metaInfo => [metaInfo.id, metaInfo])),
  ids: views.map(({ id }) => id),
});
export const availableViews$ = persistentReducedStream<AvailableViews>(
  'availableViews$',
  { views: [], byIds: {}, ids: [] },
  [reducer(setViews, setViewsReducer)]
);
