import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { ViewIds } from '@ardoq/api-types';
import { availableViews$ } from 'views/availableViews$';
import { isViewEligibleForNewAssets } from 'viewDeprecation/restrictedViews';
import { ExistentMetaInfoViewStatus } from 'views/types';
import { getViewOptionLabel } from 'views/selectViews/selectViews';

export const createViewOptions = (tabIds = availableViews$.state.ids) => {
  const locale = getCurrentLocale();

  return tabIds
    .map(tabId => {
      const metaInfo = availableViews$.state.byIds[tabId];
      return metaInfo
        ? {
            label: getViewOptionLabel(
              metaInfo.viewStatus as ExistentMetaInfoViewStatus,
              metaInfo.name ?? metaInfo.id
            ) as unknown as string,
            // this is a temporary hack until select options will support rightContent. If they already do and it's still here, please tag me or use the rightContent prop
            val: metaInfo.id,
          }
        : { label: tabId, val: tabId };
    })
    .filter(({ val }) => isViewEligibleForNewAssets(val as ViewIds))
    .sort((a, b) => localeCompare(a.val, b.val, locale));
};
