import { ActionCreator, reducer } from '@ardoq/rxbeach';
import { fetchAllSucceeded } from './actions';
import { ArdoqId, Entity } from '@ardoq/api-types';
import { ArdoqEvent, EventType } from 'sync/types';
import { logWarn } from '@ardoq/logging';

export const fetchAllReducer = <State, E extends Entity[]>(
  reducingFn: (state: State, t: E) => State
) => {
  return reducer<State, E>(
    fetchAllSucceeded as unknown as ActionCreator<E>,
    reducingFn
  );
};

type ReducerMap<State, T extends Entity> = {
  create: (state: State, data: T) => State;
  update: (state: State, data: T) => State;
  delete: (state: State, id: ArdoqId) => State;
};

export const websocketReducer =
  <State, T extends Entity>(reducerMap: ReducerMap<State, T>) =>
  (state: State, event: ArdoqEvent<T>): State => {
    const eventType = event['event-type'];
    switch (eventType) {
      case EventType.DELETE:
        return reducerMap.delete(state, event.data._id);
      case EventType.CREATE:
        return reducerMap.create(state, event.data);
      case EventType.UPDATE:
        return reducerMap.update(state, event.data);
      default:
        logWarn(new Error(`Unknown event-type "${eventType}"`));
    }
    return state;
  };
