import { initializeComponentInterface } from './components/initializeComponentInterface';
import { initializeFieldInterface } from './fields/initializeFieldInterface';
import { filterInterface } from './filters/filterInterface';
import { initializeReferenceInterface } from './references/initializeReferenceInterface';
import { initializeTagInterface } from './tags/initializeTagInterface';
import { initializeWorkspaceInterface } from './workspaces/initializeWorkspaceInterface';
import { initializeFeatureInterface } from './features/initializeFeatureInterface';
import { initializePrivilegesInterface } from 'admin/privileges';

export const initializeModelInterfaces = () => {
  filterInterface.initializeFilterInterface();
  initializeComponentInterface();
  initializeReferenceInterface();
  initializeFieldInterface();
  initializeTagInterface();
  initializeWorkspaceInterface();
  initializeFeatureInterface();
  initializePrivilegesInterface();
};
