import Tags from 'collections/tags';
import { AttributeComparators } from 'collections/attributeComparators';
import {
  JsonFilterPrefixes,
  encodingIdsToFilterNames,
  filterNamesToEncodingIds,
} from 'models/filterEnums';
import {
  BooleanOperator,
  FilterTypes,
  type FilterAttributes,
} from '@ardoq/api-types';
import { assign, isUndefined } from 'lodash';
import type { Filter, BackboneFilterAttributes } from 'aqTypes';
import type { FilterInfoOperator } from '@ardoq/api-types';

const getCommonFilterAttributes = (str: string) => {
  const parser = {
    regexp: /(?:f_)(r_)?(p_)?(c_[a-f\d]{6}_)?/i,
    affectReferenceGroup: 1,
    affectComponentGroup: 2,
    colorGroup: 3,
  };
  const match = parser.regexp.exec(str);

  return match
    ? {
        affectReference: !!match[parser.affectReferenceGroup],
        affectComponent: !!match[parser.affectComponentGroup],
        color:
          match[parser.colorGroup] &&
          `#${match[parser.colorGroup].replace('c_', '').replace('_', '')}`,
      }
    : {};
};

const getTagFilterAttributes = (str: string) => {
  const parser = {
    regexp: /(?:t_)(!)?([^&]*)(?:&)?/i,
    isNegativeGroup: 1,
    nameGroup: 2,
  };
  const match = parser.regexp.exec(str);

  return match
    ? {
        isNegative: !isUndefined(match[parser.isNegativeGroup]),
        name: match[parser.nameGroup],
        value: match[parser.nameGroup],
        tag: Tags.collection.getByName(match[parser.nameGroup]),
        type: 'tag',
      }
    : {};
};

const getAttributeFilterAttributes = (str: string) => {
  const parser = {
    regexp: /(!)?(?:@)([^=<>]+)(==|=|<|>)([^&]+)(?:&)?/i,
    isNegativeGroup: 1,
    nameGroup: 2,
    operatorGroup: 3,
    valueGroup: 4,
  };

  const match = parser.regexp.exec(str);

  if (match) {
    // Deciding to never allow just plain (=) value in a contains check,
    // as Is empty filter now will never work.
    if (
      match[parser.valueGroup] === '=' &&
      match[parser.operatorGroup] === '='
    ) {
      match[parser.valueGroup] = '';
      match[parser.operatorGroup] = '==';
    }
    const comparator = AttributeComparators.getByOperator(
      match[parser.operatorGroup],
      match[parser.nameGroup]
    );

    return {
      isNegative: !isUndefined(match[parser.isNegativeGroup]),
      name: match[parser.nameGroup],
      comparator: (comparator || {}).id,
      value: match[parser.valueGroup],
      type: 'attribute',
    };
  }
  return {};
};

const comparators = [
  '',
  'greaterThan',
  'lessThan',
  'contains',
  'equals',
  'dateMatch',
  'dateLater',
  'dateEarlier',
  'idMatch',
  'boolean',
  'dateRangeContains',
  'dateRangeDoesNotContain',
  'dateRangeBefore',
  'dateRangeAfter',
];

const comparatorToEnum = (comparator = '') => comparators.indexOf(comparator);
const enumToComparator = (enum_ = 0) => comparators[enum_];
const typeToEnum = (type?: FilterTypes) => (type === 'attribute' ? 1 : 0);
const enumToType = (enum_: number) =>
  enum_ === 1 ? FilterTypes.ATTRIBUTE : FilterTypes.TAG;
const dateRangeToEnum = (type?: FilterTypes) =>
  type === FilterTypes.DATE_RANGE ? 1 : 0;
const enumIsDateRange = (enum_: number) => enum_ === 1;
const conditionToEnum = (condition?: string) =>
  condition === BooleanOperator.AND ? 1 : 0;
const enumToCondition = (enum_: number) =>
  enum_ === 1 ? BooleanOperator.AND : BooleanOperator.OR;
const nameToEnum = (name: string) => filterNamesToEncodingIds[name] || name;
/** @param {string | number} indexOrName */
const enumToName = (indexOrName: string | number) => {
  if (!Number.isInteger(indexOrName)) return indexOrName;
  return encodingIdsToFilterNames[indexOrName as number];
};

const isBackboneModel = (filter: Filter | FilterAttributes): filter is Filter =>
  'attributes' in filter;

export const defaultFilterToArray = ({
  affectComponent,
  affectReference,
  comparator,
  isNegative,
  name,
  type,
  value,
  color,
  rules,
  condition,
}: FilterAttributes | BackboneFilterAttributes) => {
  // To ensure backwards filter URL compatibility, maintaining a value
  // for isEnabled even though it's no longer a concept we use.
  const isEnabled = true;
  const arr = [
    ((affectComponent ? 1 : 0) << 0) |
      ((affectReference ? 1 : 0) << 1) |
      ((isEnabled ? 1 : 0) << 2) |
      ((isNegative ? 1 : 0) << 3) |
      (typeToEnum(type) << 4) |
      (conditionToEnum(condition) << 5) |
      (comparatorToEnum(comparator) << 6) |
      (dateRangeToEnum(type) << 10),
    nameToEnum(name!),
    value,
  ];
  if (color) {
    arr.push(encodeURIComponent(color));
  }
  if (rules) {
    if (arr.length === 3) {
      arr.push('');
    }
    const arrayEntries = rules.map(filter =>
      defaultFilterToArray(isBackboneModel(filter) ? filter.attributes : filter)
    );
    // @ts-expect-error this is suspicious, but I'm not going to change it right now. was the intent to flatten the array before pushing it using spread syntax?
    arr.push(arrayEntries);
  }
  return arr;
};

const defaultArrayToFilter = ([
  bitflags,
  name,
  value,
  color,
  rules,
]: Array<any>) => {
  const affectComponent = Boolean((bitflags >> 0) & 1);
  const affectReference = Boolean((bitflags >> 1) & 1);
  // isEnabled bit is here, but is no longer used in Ardoq
  const isNegative = Boolean((bitflags >> 3) & 1);
  const comparator = enumToComparator(
    (bitflags >> 6) & 0b1111
  ) as FilterInfoOperator;

  const type: FilterTypes = enumIsDateRange((bitflags >> 10) & 1)
    ? FilterTypes.DATE_RANGE
    : enumToType((bitflags >> 4) & 1);

  const dict: FilterAttributes = {
    affectComponent,
    affectReference,
    isNegative,
    type,
    comparator,
    name: enumToName(name) as string,
    value,
  };
  if (color) {
    dict.color = decodeURIComponent(color);
  }
  if (rules) {
    dict.rules = rules.map((rule: Array<any>) => defaultArrayToFilter(rule));
    dict.condition = enumToCondition((bitflags >> 5) & 1);
  }
  return dict;
};

export const labelFormattingFilterToArray = ({
  affectReference,
  includeTime,
  value,
  affectsTypeName,
  showFieldName,
}: FilterAttributes) => {
  const type = affectReference ? 1 : 0;
  // To ensure backwards filter URL compatibility, maintaining a value
  // for isEnabled even though it's no longer a concept we use.
  const enabled = 1;
  const includedTime = [includeTime ? 1 : 0];
  const values = (value as string).split(',');
  return [
    type,
    enabled,
    values,
    ...includedTime,
    affectsTypeName,
    showFieldName ? 1 : 0,
  ];
};

// Element in pos. 1 is isEnabled, which is no longer used in filters
const labelFormattingArrayToFilter = ([
  type,
  ,
  values,
  isTimeIncluded,
  affectsTypeName,
  showFieldName,
]: Array<any>) => {
  const affectComponent = type === 0;
  const affectReference = type === 1;
  const includedTime = { includeTime: isTimeIncluded === 1 };
  const value = /** @type {string[]} */ values.join(',');
  return {
    affectComponent,
    affectReference,
    type: affectReference
      ? FilterTypes.REFERENCE_LABEL
      : FilterTypes.COMPONENT_LABEL,
    value,
    ...includedTime,
    affectsTypeName,
    showFieldName: showFieldName === 1,
  };
};

// JsonPrefixToFilter must be instantiated after the functions it keeps a map of
const JsonPrefixToFilter: Record<string, (arg: any) => FilterAttributes> = {
  [JsonFilterPrefixes.DEFAULT]: defaultArrayToFilter,
  [JsonFilterPrefixes.WORKSPACE_FILTER]: defaultArrayToFilter,
  [JsonFilterPrefixes.LABEL_FORMATTING]: labelFormattingArrayToFilter,
};

export const parseUriEncoded = (str: string) => {
  const isTagFilter = str.match('_t_');
  const prefixMatch = Object.keys(JsonPrefixToFilter).find(prefix =>
    str.startsWith(prefix)
  );
  if (isTagFilter) {
    return assign(getCommonFilterAttributes(str), getTagFilterAttributes(str));
  } else if (prefixMatch) {
    return JsonPrefixToFilter[prefixMatch](
      JSON.parse(str.slice(prefixMatch.length))
    );
  }
  return assign(
    getCommonFilterAttributes(str),
    getAttributeFilterAttributes(str)
  );
};
