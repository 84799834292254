import {
  CommonDefaultFilterKeys,
  ComponentDefaultFilterKeys,
  ReferenceDefaultFilterKeys,
  SpecialFilterKeys,
  _ComponentDefaultFilterKeys,
  _ReferenceDefaultFilterKeys,
} from '@ardoq/filter-interface';

export enum JsonFilterPrefixes {
  DEFAULT = 'f_json_',
  LABEL_FORMATTING = 'l_json_',
  WORKSPACE_FILTER = 'f_ws_',
  DYNAMIC_FILTER = 'df_',
}

/**
 * Mapping from the attribute and type enums to integer ids for use when
 * encoding filters to an URI query parameter
 */
export const filterNamesToEncodingIds: { [name: string]: number } = {
  [CommonDefaultFilterKeys.ID]: 0,
  [ComponentDefaultFilterKeys.VERSION]: 1,
  [CommonDefaultFilterKeys.CREATED_BY]: 2,
  [CommonDefaultFilterKeys.DESCRIPTION]: 3,
  [ReferenceDefaultFilterKeys.DISPLAY_TEXT]: 4,
  [ComponentDefaultFilterKeys.INCOMING_REF_COUNT]: 5,
  [CommonDefaultFilterKeys.LAST_UPDATED]: 6,
  [ComponentDefaultFilterKeys.NAME]: 7,
  [ComponentDefaultFilterKeys.OUTGOING_REF_COUNT]: 8,
  [ComponentDefaultFilterKeys.PARENT]: 9,
  [CommonDefaultFilterKeys.ROOT_WORKSPACE]: 10,
  [ReferenceDefaultFilterKeys.SOURCE]: 11,
  [CommonDefaultFilterKeys.TAG]: 12,
  [ReferenceDefaultFilterKeys.TARGET]: 13,
  [ReferenceDefaultFilterKeys.TARGET_WORKSPACE]: 14,
  [CommonDefaultFilterKeys.TYPE]: 15,
  [SpecialFilterKeys.COMPONENT_HAS_REFERENCE_FROM]: 16,
  [SpecialFilterKeys.COMPONENT_HAS_REFERENCE_TO]: 17,
  [CommonDefaultFilterKeys.CREATED]: 18,
};
export const encodingIdsToFilterNames = Object.getOwnPropertyNames(
  filterNamesToEncodingIds
).reduce(
  (obj, name) => {
    obj[filterNamesToEncodingIds[name]] = name;
    return obj;
  },
  {} as { [index: number]: string }
);

const ReferenceDefaultFilterKeySet = new Set(
  Object.values(ReferenceDefaultFilterKeys)
);
export const isDefaultReferenceAttribute = (val: string): boolean => {
  return ReferenceDefaultFilterKeySet.has(
    val as CommonDefaultFilterKeys | _ReferenceDefaultFilterKeys
  );
};

const ComponentDefaultFilterKeySet = new Set(
  Object.values(ComponentDefaultFilterKeys)
);
export const isDefaultComponentAttribute = (val: string): boolean => {
  return ComponentDefaultFilterKeySet.has(
    val as CommonDefaultFilterKeys | _ComponentDefaultFilterKeys
  );
};
