import {
  APIFieldAttributes,
  APIReferenceType,
  ArdoqId,
  ViewIds,
} from '@ardoq/api-types';
import { RepresentationData } from '@ardoq/data-model';
import { Point } from '@ardoq/yfiles';
import type {
  RelationshipDiagramViewSettings,
  RelationshipDiagramViewModel,
  HasViewInstanceId,
  HasViewpointMode,
} from '@ardoq/graph';
import { SettingsConfig } from '@ardoq/view-settings';
import { LayoutConstraint } from 'tabview/graphViews/types';
import { LayoutConstraintRuleProps } from './view/layoutRules/types';
import { LayoutConstraintWithSource } from './view/types';
import { ComponentTypeForLegend } from '@ardoq/view-legend';

export enum ProteanRenderingMode {
  SVG,
  Canvas,
  WebGL2,
}
export type HierarchicLayoutOrientation =
  | 'top-to-bottom'
  | 'left-to-right'
  | 'right-to-left'
  | 'bottom-to-top';

export type HierarchicLayoutOptions = {
  orthogonalRouting: boolean;
  orientation: HierarchicLayoutOrientation;
  separateReferences: boolean;
  compactGroups: boolean;
  directedEdges: boolean;
  recursiveGroupLayering: boolean;
  sequenceConstraints: LayoutConstraint[];
  layerConstraints: LayoutConstraint[];
  separateLayers: boolean;
  minimumLayerDistance: number;
  nodeToNodeDistance: number;
  layoutRules: LayoutConstraintRuleProps[];
};
type OrganicLayoutOptions = {
  nodeEdgeOverlapAvoided: boolean;
  compactnessFactor: number;
};
export type HasEdgeBundling = { edgeBundling: number };
export type CircularLayoutStyle =
  | 'bcc-compact'
  | 'bcc-isolated'
  | 'custom-groups'
  | 'single-cycle';
type CircularLayoutOptions = HasEdgeBundling & {
  layoutStyle: CircularLayoutStyle;
};

export type RadialLayoutLayeringStrategy =
  | 'bfs'
  | 'dendrogram'
  | 'hierarchical';
type RadialLayoutOptions = HasEdgeBundling & {
  layeringStrategy: RadialLayoutLayeringStrategy;
};
type OrthogonalLayoutOptions = {
  gridSpacing: number;
  crossingReduction: boolean;
  edgeLengthReduction: boolean;
  optimizePerceivedBends: boolean;
  uniformPortAssignment: boolean;
};
export type SpatialMapLayoutOptions = {
  selectedFieldNameX: string;
  selectedFieldNameY: string;
};
type SwimlanesLayoutOptions = { isVertical: boolean };
type CactusLayoutOptions = Record<string, never>;
type TreeLayoutOptions = Record<string, never>;
type TreeMapLayoutOptions = Record<string, never>;

type ProteanTabularLayoutSettingsBase = {
  columnInsets: number;
  rowInsets: number;
  minColumnSize: number;
  minRowSize: number;
  separateReferences: boolean;
};
export type ProteanTabularLayoutStageConfig =
  ProteanTabularLayoutSettingsBase & {
    layoutType: ProteanLayoutType.Tabular | ProteanLayoutType.HierarchicInGrid;
  };
export type TabularLayoutOptions = ProteanTabularLayoutSettingsBase & {
  columnConstraintsWithSource: LayoutConstraintWithSource[];
  rowConstraintsWithSource: LayoutConstraintWithSource[];
  columnSpans: LayoutConstraint[];
  rowSpans: LayoutConstraint[];
  layoutRules: LayoutConstraintRuleProps[];
};
export type HierarchicInGridLayoutOptions = TabularLayoutOptions;
export type ProteanLayoutOptions = {
  hierarchic: HierarchicLayoutOptions;
  organic: OrganicLayoutOptions;
  circular: CircularLayoutOptions;
  radial: RadialLayoutOptions;
  orthogonal: OrthogonalLayoutOptions;
  spatialMap: SpatialMapLayoutOptions;
  swimlanes: SwimlanesLayoutOptions;
  cactus: CactusLayoutOptions;
  tree: TreeLayoutOptions;
  treeMap: TreeMapLayoutOptions;
  tabular: TabularLayoutOptions;
  hierarchicInGrid: HierarchicInGridLayoutOptions;
};
export type ProteanDiagramViewSettings = RelationshipDiagramViewSettings & {
  layoutType: ProteanLayoutType;
  renderingMode: ProteanRenderingMode;
  layoutOptions: Partial<ProteanLayoutOptions>;
};

export type ProteanDiagramStreamedProperties = HasViewInstanceId &
  HasViewpointMode & {
    viewSettings: ProteanDiagramViewSettings;
    viewModel: RelationshipDiagramViewModel;
    nodeRepresentationData: Map<string, RepresentationData>;
    referenceTypes: Map<ArdoqId, APIReferenceType>;
    numericFields: APIFieldAttributes[];
    nodePositions: Map<string, Point>;
    componentTypes: ComponentTypeForLegend[];
  };
export type ProteanDiagramStreamedProps = { isEditModeEnabled: boolean };
type ProteanDiagramOwnProperties = {
  leftMenuFilter?: (items: SettingsConfig[]) => SettingsConfig[];
  viewId?: ViewIds;
};
export type ProteanDiagramViewProperties = ProteanDiagramStreamedProperties &
  ProteanDiagramOwnProperties;

export enum ProteanLayoutType {
  Hierarchic,
  Organic,
  Orthogonal,
  Circular,
  Radial,
  CompactDisk,
  Swimlanes,
  SpatialMap,
  GeographicMap,
  Cactus,
  Tree,
  TreeMap,
  HierarchicInGrid,
  Tabular,
}
