import { ViewIds } from '@ardoq/api-types';
import UserSettings from 'models/UserSettings';
import { EMPTY, Observable, from } from 'rxjs';
import { isPresentationMode } from 'appConfig';
import defaultState from 'views/defaultState';
import { throwDefaultViewStateError } from './utils';
import {
  trackViewSettingsChange,
  trackViewSettingChangeWithCategory,
} from './tracking';
import { isNil } from 'lodash';
import { loadedGraph$ } from '../traversals/loadedGraph$';

const userSettingsModels = new Map<string, UserSettings>();

const loadUserSettings = async (
  settings: string[],
  userSettings: UserSettings
) => {
  const savedSettings: any = {};
  for (const setting of settings) {
    const value = await userSettings.get(setting);
    if (!isNil(value)) {
      savedSettings[setting] = value;
    }
  }

  return savedSettings;
};

const getUserSettingsInstance = (viewId: ViewIds | string) => {
  if (!userSettingsModels.has(viewId)) {
    userSettingsModels.set(viewId, new UserSettings(viewId));
  }
  return userSettingsModels.get(viewId)!;
};

type SaveAndTrackViewSettingsChangeArgs = {
  changedSettings: [name: string, value: any][];
  viewId: ViewIds;
};
export const saveAndTrackViewSettingsChange = ({
  changedSettings,
  viewId,
}: SaveAndTrackViewSettingsChangeArgs) => {
  const userSettingsInstance = getUserSettingsInstance(viewId);

  userSettingsInstance.setMultiple(changedSettings);
  trackViewSettingsChange({ viewId, changedSettings });

  if (changedSettings.length === 1 && loadedGraph$.state.isViewpointMode) {
    trackViewSettingChangeWithCategory({
      viewId,
      changedSetting: changedSettings[0],
    });
  }
};

export const getLoadSavedViewSettingsStream = (
  viewId: ViewIds
): Observable<UserSettings> => {
  const defaultSettings = defaultState.get(viewId);
  if (!defaultSettings) {
    throwDefaultViewStateError(viewId);
  }
  return isPresentationMode()
    ? EMPTY
    : from(
        loadUserSettings(
          Object.keys(defaultSettings),
          getUserSettingsInstance(viewId)
        )
      );
};
