import { ArdoqId } from '@ardoq/api-types';
import Backbone from 'backbone';

import { CollectionView, ExcludedSet } from './consts';

// Named sets are lists of entity ids to be hidden. The map of named sets
// is shared between views so that a view can be composed of different
// named sets. The different named sets for a given view are stored in
// activeExcludedNamedSets. All views are always updated if a named set
// gets changed.

class ViewCollection<T extends Backbone.Model> extends Backbone.Collection<T> {
  excludedIds: Set<ArdoqId>;
  activeExcludedNamedSets: Set<string | ExcludedSet>;
  views: Map<string, this>;
  namedExcludedSets: Map<ExcludedSet, ArdoqId[]>;
  constructor(...args: any) {
    super(...args);

    this.excludedIds = new Set<ArdoqId>();
    this.activeExcludedNamedSets = new Set<string | ExcludedSet>([
      ExcludedSet.DEFAULT_SET_NAME,
    ]);
    this.views = new Map<string, this>();
    this.namedExcludedSets = new Map<ExcludedSet, ArdoqId[]>();

    this.views.set(CollectionView.DEFAULT_VIEW, this);
  }
  createCollectionView = (name: string, includedSets: string[] = []) => {
    // Only excludedIds and activeExcludedNamedSets are instance members,
    // everything else is in the prototype.
    const view: this = Object.create(this);
    view.excludedIds = new Set();
    view.activeExcludedNamedSets = new Set([name, ...includedSets]);
    this.views.set(name, view);
    Object.freeze(view);
    return view;
  };

  reset(
    models?: Array<T | Record<string, unknown>>,
    options?: Backbone.Silenceable | undefined
  ) {
    // This may be called by Backbone before our constructor has finished
    // executing, so we need to make sure the fields are initialized, even though
    // TypeScript says they will be.
    this.namedExcludedSets?.clear();
    this.excludedIds?.clear();
    return super.reset(models, options);
  }

  addExcludedFromView(
    ids: ArdoqId[],
    setName: ExcludedSet = ExcludedSet.DEFAULT_SET_NAME
  ) {
    this.activeExcludedNamedSets.add(setName);
    this.namedExcludedSets.set(setName, [
      ...(this.namedExcludedSets.get(setName) || []),
      ...ids,
    ]);
    this.updateExcludedIds();
  }

  removeExcludedFromView(
    ids: ArdoqId[],
    setName: ExcludedSet = ExcludedSet.DEFAULT_SET_NAME
  ) {
    this.namedExcludedSets.set(
      setName,
      (this.namedExcludedSets.get(setName) || []).filter(
        (id: ArdoqId) => !ids.includes(id)
      )
    );
    this.updateExcludedIds();
  }

  clearExcludedFromView(setName?: ExcludedSet) {
    if (setName) {
      this.namedExcludedSets.delete(setName);
      this.updateExcludedIds();
    } else {
      this.namedExcludedSets.clear();
      this.excludedIds.clear();
    }
  }

  updateExcludedIds() {
    for (const view of this.views.values()) {
      view.excludedIds.clear();
      Array.from(this.namedExcludedSets.entries())
        .filter(([name]) => view.activeExcludedNamedSets.has(name))
        .map(([, ids]) => ids)
        .flat()
        .forEach(id => view.excludedIds.add(id));
    }
  }

  forEach(callback: (model: T, index: number, list: T[]) => void) {
    const modelView = this.getModelView();
    modelView.forEach(callback);
    return modelView;
  }

  filter(callback: (model: T, index: number, list: T[]) => boolean): T[] {
    return this.getModelView().filter(callback);
  }

  each(callback: (model: T, index: number, list: T[]) => void) {
    const modelView = this.getModelView();
    modelView.forEach(callback);
    return modelView;
  }

  map<R>(callback: (model: T, index: number, list: T[]) => R) {
    return this.getModelView().map(callback);
  }

  toArray() {
    return this.getModelView().slice();
  }

  getExcludedIds(name: ExcludedSet): Set<ArdoqId> {
    return new Set(this.namedExcludedSets.get(name) || []);
  }

  getModelView() {
    return this.excludedIds.size === 0
      ? this.models
      : this.models.filter(entity => !this.excludedIds.has(entity.id));
  }
}

export default ViewCollection;
