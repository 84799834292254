import {
  ContextSort,
  NumericSortOrder,
  SortAttribute,
} from '@ardoq/common-helpers';

export const getDefaultSort = (): ContextSort => ({
  attr: SortAttribute.ORDER,
  name: 'Order',
  order: NumericSortOrder.ASC,
});
