import Backbone from 'backbone';
import Fields from 'collections/fields';
import GroupBy from 'models/groupBy';
import type {
  ComponentBackboneModel,
  FieldBackboneModel,
  GroupByBackboneModel,
  Reference,
  Tag,
} from 'aqTypes';
import { debounce } from 'lodash';
import { each } from 'underscore';

function getCompWsId(comp: ComponentBackboneModel) {
  if (comp && comp.getWorkspace) {
    return (comp.getWorkspace() || {}).id;
  }
}

const isFieldGroup = (groupBy: GroupByBackboneModel) => groupBy.isTiedToField();

class GroupByCollection extends Backbone.Collection<GroupByBackboneModel> {
  model = GroupBy.model;
  notifyOnAffectingChange = debounce(
    ({ fieldNames = [] }: { fieldNames?: string[] }) => {
      const isAffected = fieldNames.some(fieldName =>
        this.hasGroupAffectedByField({ fieldName })
      );

      if (isAffected) {
        this.trigger('change');
      }
    },
    50
  );
  addFromString(groupByString: string) {
    this.add(GroupBy.createFromString(groupByString));
  }

  getAll() {
    return this.models;
  }

  hasGroupByChild(comp: ComponentBackboneModel) {
    const compWsId = getCompWsId(comp);
    return (
      !!compWsId &&
      !!this.findWhere({
        workspaceId: compWsId,
        targetId: comp.getTypeId(),
        type: GroupBy.types.CHILD,
      })
    );
  }

  hasGroupByParent(comp: ComponentBackboneModel) {
    const compWsId = getCompWsId(comp);
    return (
      !!compWsId &&
      !!this.findWhere({
        workspaceId: comp.getWorkspace()!.id,
        targetId: comp.getTypeId(),
        type: GroupBy.types.PARENT,
      })
    );
  }

  hasGroupByParentAll() {
    return !!this.findWhere({
      type: GroupBy.types.PARENT_ALL,
    });
  }

  hasGroupByWorkspace() {
    return !!this.findWhere({
      type: GroupBy.types.WORKSPACE,
    });
  }

  hasGroupByComponentType() {
    return !!this.findWhere({
      type: GroupBy.types.COMPONENT,
    });
  }

  hasGroupByOutgoingReference(ref: Reference) {
    return !!this.findWhere({
      workspaceId: ref.getSource().get('rootWorkspace'),
      targetId: `${ref.getType()}`,
      type: GroupBy.types.REFERENCE,
      direction: GroupBy.directions.outgoing,
    });
  }

  hasGroupByIncomingReference(ref: Reference) {
    return !!this.findWhere({
      workspaceId: ref.getSource().get('rootWorkspace'),
      targetId: `${ref.getType()}`,
      type: GroupBy.types.REFERENCE,
      direction: GroupBy.directions.incoming,
    });
  }

  hasGroupByTag(tag: Tag) {
    return !!this.findWhere({
      targetId: tag.id,
      type: GroupBy.types.TAG,
    });
  }

  hasGroupByField(field: FieldBackboneModel) {
    return !!this.findWhere({
      targetId: field.id,
      type: GroupBy.types.FIELD,
    });
  }

  hasGroupAffectedByField({ fieldName }: { fieldName: string }) {
    return this.filter(isFieldGroup).some(groupBy => {
      const field = Fields.collection.get(groupBy.get('targetId'));
      return field && field.get('name') === fieldName;
    });
  }

  removeAll() {
    const groupBys = this.toArray();
    each(
      groupBys,
      element => {
        this.remove(element);
      },
      this
    );
  }
}

export default new GroupByCollection();
