import {
  setCurrentUserPermissions,
  addPermissionForResource,
  fetchedCurrentUserPermissions,
} from './actions';
import {
  UserResourcePermissionsRecord,
  permissionsOperations,
} from '@ardoq/access-control';
import { APICurrentUserPermission } from '@ardoq/api-types';
import { ExtractPayload, reducer } from '@ardoq/rxbeach';

/**
 * Add single permission for a resource
 * This purposefully does not use PermissionContext because it generates a part
 * of the context, so adding it here would make loopy logic, at which case it
 * would be better to have userId/role in the state.
 */
const handleAddPermissionForResource = (
  state: UserResourcePermissionsRecord,
  { permission, currentUser }: ExtractPayload<typeof addPermissionForResource>
) => {
  return permissionsOperations.addApiResourcePermissionAttribute(state, {
    permission,
    user: currentUser,
  });
};

/**
 * Overwrite entire permission state with incoming user resource permissions
 * that are expected to be the most up-to-date permissions.
 */
const handleFetchedPermissions = (
  _: UserResourcePermissionsRecord,
  permissions: APICurrentUserPermission[]
) => {
  return permissionsOperations.fromApiUserResourcePermissions(permissions);
};

const setCurrentUserPermissionsReducerFn = (
  _: UserResourcePermissionsRecord,
  newPermissions: UserResourcePermissionsRecord
) => {
  return newPermissions;
};

const handleSetCurrentUserPermissions = reducer(
  setCurrentUserPermissions,
  setCurrentUserPermissionsReducerFn
);

export default [
  reducer(fetchedCurrentUserPermissions, handleFetchedPermissions),
  reducer(addPermissionForResource, handleAddPermissionForResource),
  handleSetCurrentUserPermissions,
];
