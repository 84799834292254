import { createElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { ImageListItem } from 'aqTypes';

type DropdownOptionContentProps = {
  readonly uri: string | null;
  readonly label: string;
};

const DropdownOptionContent = ({ uri, label }: DropdownOptionContentProps) => {
  if (uri === null) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
            width: '24px',
            fontSize: '18px',
            lineHeight: 'inherit',
            marginRight: '8px',
          }}
        />
        {label}
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '24px',
          width: '24px',
          fontSize: '18px',
          lineHeight: 'inherit',
          marginRight: '8px',
        }}
      >
        <img
          src={uri}
          style={{
            margin: 0,
            maxHeight: '18px',
            maxWidth: '18px',
            width: 'auto',
            height: 'auto',
          }}
        />
      </div>
      {label}
    </div>
  );
};

function createHTMLStringForImageOption(uri: string | null, label: string) {
  return renderToStaticMarkup(
    createElement(DropdownOptionContent, { uri, label })
  );
}

interface DocumentArchiveImage {
  filename: string;
  uri: string;
}

export const noImageOption = {
  label: 'None (use icon)',
  value: null,
  isSelected: false,
  dataContent: createHTMLStringForImageOption(null, 'None (use icon)'),
};

/** @deprecated */
export function getImageSelectList_DEPRECATED(
  documentArchiveImages: DocumentArchiveImage[],
  selectedId: string | null
): ImageListItem[] {
  const options = documentArchiveImages.map(({ filename, uri }) => ({
    label: filename,
    value: uri,
    isSelected: Boolean(selectedId && selectedId === uri),
    dataContent: createHTMLStringForImageOption(uri, filename),
  }));

  return options;
}
