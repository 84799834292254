import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import { combineLatest, map } from 'rxjs';
import currentUser$ from '../currentUser/currentUser$';
import { currentUserPermissions$ } from './currentUserPermissions$';
import { persistentReducedStream, streamReducer } from '@ardoq/rxbeach';

const _currentUserPermissionContext$ = combineLatest({
  user: currentUser$,
  permissions: currentUserPermissions$,
}).pipe(
  map(({ user, permissions }) => {
    if (!user || !permissions) {
      return permissionsOperations.createEmptyPermissionContext();
    }
    return permissionsOperations.toPermissionContext(user, permissions);
  })
);

const currentUserPermissionContextReducer = (
  _: PermissionContext,
  value: PermissionContext
): PermissionContext => value;

const currentUserPermissionContext$ = persistentReducedStream(
  'currentUserPermissionContext2$',
  permissionsOperations.createEmptyPermissionContext(),
  [
    streamReducer(
      _currentUserPermissionContext$,
      currentUserPermissionContextReducer
    ),
  ]
);

export default currentUserPermissionContext$;
