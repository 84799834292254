import { currentDate, differenceInSeconds } from '@ardoq/date-time';
import { BasicModel } from 'aqTypes';

type modelsNeedSavingProps = {
  models: BasicModel[];
  forced: boolean;
  hasWriteAccessCheck: (model: BasicModel) => boolean;
};

export function modelsNeedSaving({
  models = [],
  forced = false,
  hasWriteAccessCheck,
}: modelsNeedSavingProps) {
  return models.filter(model => {
    if (!model.changedAndMustBeSaved?.() || !hasWriteAccessCheck(model)) {
      return false;
    }

    const changedMoreThan30sAgo =
      !model.lastChangeTime ||
      differenceInSeconds(currentDate(), model.lastChangeTime) > 30;

    return forced || !model.editing || changedMoreThan30sAgo;
  });
}
