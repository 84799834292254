import { reducer } from '@ardoq/rxbeach';
import { fetchedOrgUsersSuccess } from './actions';
import { OrgUsersStreamShape } from './types';
import { APIOrganizationUser } from '@ardoq/api-types';
import { toByIdDictionary } from '@ardoq/common-helpers';

const fetchedOrgUsersSuccessReducer = (
  state: OrgUsersStreamShape,
  payload: APIOrganizationUser[]
): OrgUsersStreamShape => {
  return {
    ...state,
    users: payload,
    byId: toByIdDictionary(payload),
  };
};

export const reducers = [
  reducer(fetchedOrgUsersSuccess, fetchedOrgUsersSuccessReducer),
];
