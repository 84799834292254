import { getCurrentLocale, localeCompareNumericLowercase } from '@ardoq/locale';
import {
  ComponentDefaultFilterKeys,
  ReferenceDefaultFilterKeys,
  type FilterAttribute,
} from '@ardoq/filter-interface';
import type { FilterFieldAttributeItem } from 'aqTypes';
import Context from 'context';
import Fields from 'collections/fields';
import { LabelFormattingInfo } from '@ardoq/data-model';
import {
  getComponentLabelFormattingFilterAttributes,
  getReferenceLabelFormattingFilterAttributes,
} from './formattingRulesToFormattingAttributes';
import {
  ComponentLabelFormattingAttributes,
  ReferenceLabelFormattingAttributes,
} from '@ardoq/api-types';

type AppendFieldAttributesArgs = {
  attributes: FilterFieldAttributeItem[];
  globalAttr: 'global' | 'globalref';
  typeAttr: 'componentType' | 'referenceType';
};

const appendFieldAttributes = ({
  attributes,
  globalAttr,
  typeAttr,
}: AppendFieldAttributesArgs) => {
  const modelIds = Context.workspaces().map(ws => ws.getModel()?.id);
  const locale = getCurrentLocale();

  Fields.collection
    .filter(field => modelIds.includes(field.getModelId()))
    .filter(
      field =>
        field.attributes[globalAttr] ||
        (field.attributes[typeAttr] && field.attributes[typeAttr].length)
    )
    .map(field => {
      return {
        name: field.name(),
        label: field.getRawLabel(),
        fieldId: field.id,
      };
    })
    .sort((a, b) => localeCompareNumericLowercase(a.label, b.label, locale))
    .forEach(fieldAttr => {
      // remove duplicates, only need to check last since the list is sorted
      if (
        fieldAttr.label !== (attributes.slice(-1)[0] as FilterAttribute).label
      ) {
        attributes.push(fieldAttr);
      }
    });
};

export const mapComponentAttributes = () => {
  const attributes: FilterFieldAttributeItem[] = [
    {
      name: ComponentDefaultFilterKeys.NAME,
      label: 'Name',
    },
    {
      name: ComponentDefaultFilterKeys.DESCRIPTION,
      label: 'Description',
    },
    {
      name: ComponentDefaultFilterKeys.TYPE,
      label: 'Type',
    },
    {
      name: ComponentDefaultFilterKeys.ID,
      label: 'ID',
    },
    {
      name: ComponentDefaultFilterKeys.PARENT,
      label: 'Children of',
    },
    {
      name: ComponentDefaultFilterKeys.VERSION,
      label: 'Revision',
    },
    {
      name: ComponentDefaultFilterKeys.CREATED,
      label: 'Created date',
    },
    {
      name: ComponentDefaultFilterKeys.LAST_UPDATED,
      label: 'Last updated',
    },
    {
      name: ComponentDefaultFilterKeys.INCOMING_REF_COUNT,
      label: 'Incoming references',
    },
    {
      name: ComponentDefaultFilterKeys.OUTGOING_REF_COUNT,
      label: 'Outgoing references',
    },
    {
      divider: true,
    },
  ];

  appendFieldAttributes({
    attributes,
    globalAttr: 'global',
    typeAttr: 'componentType',
  });

  return attributes;
};

export const mapReferencesAttributes = () => {
  const attributes: FilterFieldAttributeItem[] = [
    {
      name: ReferenceDefaultFilterKeys.DISPLAY_TEXT,
      label: 'Display Text',
    },
    {
      name: ReferenceDefaultFilterKeys.DESCRIPTION,
      label: 'Description',
    },
    {
      name: ReferenceDefaultFilterKeys.TYPE,
      label: 'Type',
    },
    {
      name: ReferenceDefaultFilterKeys.ID,
      label: 'ID',
    },
    {
      name: ReferenceDefaultFilterKeys.CREATED,
      label: 'Created date',
    },
    {
      name: ReferenceDefaultFilterKeys.LAST_UPDATED,
      label: 'Last updated',
    },
    {
      name: ReferenceDefaultFilterKeys.TARGET,
      label: 'Reference to',
    },
    {
      name: ReferenceDefaultFilterKeys.SOURCE,
      label: 'Reference from',
    },
    {
      name: ReferenceDefaultFilterKeys.TARGET_WORKSPACE,
      label: 'Show references to workspace',
    },
    {
      name: ReferenceDefaultFilterKeys.ROOT_WORKSPACE,
      label: 'Show references from workspace',
    },
    {
      divider: true,
    },
  ];

  appendFieldAttributes({
    attributes,
    globalAttr: 'globalref',
    typeAttr: 'referenceType',
  });

  return attributes;
};

export const fieldNameToFilterAttributes =
  ({ typeName, type, showFieldName }: LabelFormattingInfo) =>
  (fieldName: string) =>
    type === 'component'
      ? ({
          ...getComponentLabelFormattingFilterAttributes(fieldName, false),
          showFieldName: Boolean(showFieldName),
          ...(typeName && { affectsTypeName: typeName }),
        } satisfies ComponentLabelFormattingAttributes)
      : ({
          ...getReferenceLabelFormattingFilterAttributes(fieldName, false),
          showFieldName: Boolean(showFieldName),
          ...(typeName && { affectsTypeName: typeName }),
        } satisfies ReferenceLabelFormattingAttributes);

export const labelFormattingToFilterAttributes = (
  formatting: LabelFormattingInfo
) => formatting.fields.map(fieldNameToFilterAttributes(formatting));
