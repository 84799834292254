import { persistentReducedStream } from '@ardoq/rxbeach';
import { subdivisionReducers } from './reducers';
import { SubdivisionsStreamShape } from './types';
import { subdivisionsOperations } from '@ardoq/subdivisions';

const initialSubdivisionsStreamState: SubdivisionsStreamShape = {
  ...subdivisionsOperations.getEmptySubdivisionsContext(),
  isFetching: false,
  fetchingError: null,
  isSubmitting: false,
  submittingError: null,
};

const subdivisions$ = persistentReducedStream<SubdivisionsStreamShape>(
  'subdivisions$',
  initialSubdivisionsStreamState,
  subdivisionReducers
);

export default subdivisions$;
