import AQ from 'ardoq';
import Backbone from 'backbone';
import Fields from 'collections/fields';
import Tags from 'collections/tags';
import { AttributeComparators } from '../collections/attributeComparators';
import {
  JsonFilterPrefixes,
  isDefaultComponentAttribute,
  isDefaultReferenceAttribute,
} from './filterEnums';
import {
  defaultFilterToArray,
  labelFormattingFilterToArray,
  parseUriEncoded,
} from 'models/utils/filterURLEncodeDecode';
import { isUndefinedOrNull } from 'utils/collectionUtil';
import { logError } from '@ardoq/logging';
import { SpecialFilterKeys } from '@ardoq/query-builder';
import {
  CommonDefaultFilterKeys,
  ComponentDefaultFilterKeys,
  DynamicFilterValues,
  isResult,
  isSpecialAttributeType,
  parseFilterValue,
  isHasReferenceToFilter,
  isHasReferenceFromFilter,
} from '@ardoq/filter-interface';
import { sourceHasTarget, targetHasSource } from './simpleGraphModel';
import type {
  BackboneFilterAttributes,
  ComponentBackboneModel,
  Filter as FilterBackboneModel,
  Reference,
} from 'aqTypes';
import {
  BooleanOperator,
  FilterInfoOperator,
  FilterTypes,
} from '@ardoq/api-types';
import type { AttributeComparator } from '@ardoq/filter-interface';
import type { APIReferenceType } from '@ardoq/api-types';
import Context from '../context';
import { dateRangeOperations } from '@ardoq/date-range';
import { fieldInterface } from '@ardoq/field-interface';
import { isDateFieldType } from '@ardoq/date-time';
import type Filters from 'collections/filters';
import { find, isFunction, isString, isUndefined } from 'lodash';

/**
 * call this function only when the filter value is "current component."
 * it will resolve the value based on which attribute is being matched,
 * or just return "current component" back, according to the idiosyncratic and often
 * inconsistent rules by which this feature was implemented.
 */
const resolveCurrentComponentAttributeFilterValue = (
  filterAttributeName: string
) =>
  filterAttributeName === ComponentDefaultFilterKeys.COMPONENT_KEY
    ? Context.component()?.get(filterAttributeName)
    : DynamicFilterValues.COMPONENT_CURRENT;

// `isComponent` and `isReference` have logic inlined from `utils/typeCheck.js`,
// and do not use an instanceof check, in order to break a Circular dependency:
//   filter -/> TypeCheck -> Component -> filters -> filter
function isComponent(model: ComponentBackboneModel | Reference) {
  return model.urlRoot === '/api/component';
}
function isReference(
  model: ComponentBackboneModel | Reference
): model is Reference {
  return model.urlRoot === '/api/reference';
}
function getFieldForFilterAttribute(
  obj: ComponentBackboneModel | Reference,
  filteredAttributeName: string
) {
  const fields = isReference(obj)
    ? Fields.collection.getByReference(obj)
    : Fields.collection.getByComponent(obj);

  return fields.find(field => field.get('name') === filteredAttributeName);
}

function handleSpecialAttributeType(
  filterAttributeName: SpecialFilterKeys,
  filterValue: string,
  componentId: string
) {
  const filterValueOrResult = parseFilterValue({
    filterValue,
    currentComponentId: Context.componentId() ?? '',
  });

  if (isResult(filterValueOrResult)) {
    // Will always be true, as no code currently short-circuts to never
    // match anything
    return filterValueOrResult;
  }

  if (isHasReferenceToFilter(filterAttributeName)) {
    return targetHasSource(filterValueOrResult, componentId);
  }
  if (isHasReferenceFromFilter(filterAttributeName)) {
    return sourceHasTarget(filterValueOrResult, componentId);
  }
  return false;
}

/**
 * Check whether a model has an attribute.
 *
 * A model (reference or component) is considered to have an attribute if the
 * attribute is either a default attribute for that model type (`target` for
 * example), or if the type of the model has a custom field with that name
 * defined.
 *
 * @param obj The component or reference instance/object to check
 * @param filteredAttributeName The name of the attribute to check for
 */
const modelHasAttribute = (
  obj: ComponentBackboneModel | Reference,
  filteredAttributeName: string
) => modelHasAttributeWithField(obj, filteredAttributeName).hasAttribute;
const modelHasAttributeWithField = (
  obj: ComponentBackboneModel | Reference,
  filteredAttributeName: string
) => {
  const defaultComponentAttribute =
    isComponent(obj) && isDefaultComponentAttribute(filteredAttributeName);
  const defaultReferenceAttribute =
    isReference(obj) && isDefaultReferenceAttribute(filteredAttributeName);

  if (defaultComponentAttribute || defaultReferenceAttribute) {
    return { hasAttribute: true };
  }
  const field = getFieldForFilterAttribute(obj, filteredAttributeName);
  return field ? { hasAttribute: true, field } : { hasAttribute: false };
};

const Filter: typeof FilterBackboneModel = Backbone.Model.extend({
  _filterName: undefined,

  defaults: {
    comparator: undefined,
    affectComponent: false,
    affectReference: false,
    isNegative: false,
    name: '', // attribute filters will lookup this key
    tag: undefined,
    type: undefined, // attribute|tag
    value: '', // the actual value being searched for
  },
  isFormattingFilter: function (this: FilterBackboneModel) {
    return Boolean(this.get('color'));
  },
  isComponentFormatting: function (this: FilterBackboneModel) {
    return this.isFormattingFilter() && this.get('affectComponent');
  },
  isReferenceFormatting: function (this: FilterBackboneModel) {
    return this.isFormattingFilter() && this.get('affectReference');
  },

  setFilterName: function (this: FilterBackboneModel, name: string) {
    // use to cache target's name
    this._filterName = name;
    return this;
  },
  getFilterName: function (this: FilterBackboneModel) {
    return this._filterName;
  },
  initialize: function (
    this: FilterBackboneModel,
    attributes: BackboneFilterAttributes | string
  ) {
    // please call from Filters.createFilter to ensure it belongs to a collection
    if (isString(attributes)) {
      this.set(/** @type {FilterAttributes} */ parseUriEncoded(attributes));
    }

    if (
      isUndefined(this._filterName) &&
      this.attributes.comparator === 'idMatch'
    ) {
      let filterName;
      if (
        this.attributes.name === 'rootWorkspace' ||
        this.attributes.name === 'type'
      ) {
        return this;
      }
      if (this.attributes.affectComponent) {
        const component = AQ.globalComponents.get(this.attributes.value);
        filterName = component && component.get('name');
      } else if (this.attributes.affectReference) {
        if (
          this.attributes.name === 'target' ||
          this.attributes.name === 'source'
        ) {
          const component = AQ.globalComponents.get(this.attributes.value);
          filterName = component
            ? component.get('name')
            : this.attributes.value;
        } else {
          const component = AQ.references.get(this.attributes.value);
          filterName = component
            ? component.get('name')
            : this.attributes.value;
        }
      }
      this.setFilterName(filterName);
    }

    return this;
  },
  destroy: function (this: FilterBackboneModel, options = {}) {
    if (this.attributes.rules) {
      (this.attributes.rules as FilterBackboneModel[]).forEach(filter =>
        filter.destroy()
      );
    }
    if (this.collection) {
      (this.collection as typeof Filters).removeFilter(this, {
        shouldTriggerChangeEvent: false,
      });
    }
    Backbone.Model.prototype.destroy.call(this, options);
  },
  getComparator: function (this: FilterBackboneModel) {
    return AttributeComparators.getById(this.get('comparator'));
  },
  getAvailableComparators: function (this: FilterBackboneModel) {
    return AttributeComparators.getAvailableComparators(this.get('name'));
  },
  isAttributeFilter: function (this: FilterBackboneModel) {
    return [FilterTypes.ATTRIBUTE, FilterTypes.DATE_RANGE].includes(
      this.attributes.type
    );
  },
  isTagFilter: function (this: FilterBackboneModel) {
    return this.attributes.type === FilterTypes.TAG;
  },
  isComponentTagFilter: function (this: FilterBackboneModel) {
    return (
      this.attributes.type === FilterTypes.TAG && this.get('affectComponent')
    );
  },
  isReferenceTagFilter: function (this: FilterBackboneModel) {
    return (
      this.attributes.type === FilterTypes.TAG && this.get('affectReference')
    );
  },
  isCompLabelFilter: function (this: FilterBackboneModel): boolean {
    return (
      this.attributes.type === FilterTypes.COMPONENT_LABEL &&
      this.get('affectComponent')
    );
  },
  isRefLabelFilter: function (this: FilterBackboneModel): boolean {
    return (
      this.attributes.type === FilterTypes.REFERENCE_LABEL &&
      this.get('affectReference')
    );
  },
  isWorkspaceFilter: function (this: FilterBackboneModel) {
    return (
      this.collection &&
      (this.collection as typeof Filters).workspaceFilter === this
    );
  },
  isAffected: function (
    this: FilterBackboneModel,
    model: ComponentBackboneModel | Reference
  ) {
    if (model) {
      return (
        (this.get('affectComponent') && isComponent(model)) ||
        (this.get('affectReference') && isReference(model))
      );
    }
  },
  isIncluded: function (
    this: FilterBackboneModel,
    obj: ComponentBackboneModel | Reference
  ) {
    const {
      condition,
      rules,
      type,
    }: {
      condition: BooleanOperator;
      rules: { isIncluded: (obj: Backbone.Model) => boolean }[];
      type: FilterTypes.ATTRIBUTE | FilterTypes.TAG | FilterTypes.DATE_RANGE;
    } = this.attributes;
    if (condition) {
      if (condition === BooleanOperator.AND)
        return rules.every(rule => rule.isIncluded(obj));
      if (condition === BooleanOperator.OR)
        return rules.some(rule => rule.isIncluded(obj));
    }
    const filterTypeHandlers = {
      [FilterTypes.ATTRIBUTE]: () => this.isAttributeIncluded(obj),
      [FilterTypes.DATE_RANGE]: () => this.isAttributeIncluded(obj),
      [FilterTypes.TAG]: () => this.isTagIncluded(obj),
    };
    return filterTypeHandlers[type]();
  },
  isTagIncluded: function (
    this: FilterBackboneModel,
    obj: ComponentBackboneModel | Reference
  ) {
    if (!this.isAffected(obj)) {
      return true;
    }
    const tagName: string = this.get('name');
    const isIncluded = Tags.collection.some(
      tag => tag.name() === tagName && tag.contains(obj)
    );
    return this.get('isNegative') ? !isIncluded : isIncluded;
  },
  /**
   * Check if models which are missing the test field should be included.
   *
   * Models missing the test field should be included if this filter is an
   * excluding filter. A filter is an excluding filter if the main logical goal
   * is to exclude elements that matches a criteria. In many cases this is
   * equvivalent to being an inverted filter, because our filters are mainly
   * defined as including filters.
   *
   * The reason we need a method for this, and cannot rely on simply using
   * `this.get('isNegative')`, is edge cases. These are:
   *  * `"is empty"` is implemented as `"equals undefined/null/''"`, which is an
   *      inclusive filter (include where value is nothing), even though the
   *      logical meaning is negative: Exclude everything with a value.
   *  * `"is not empty"` is implemented as `"inverted equals undefined/null/''"`,
   *      which is a excluding filter (exclude where value is nothing), even
   *      though the logical meaning is positive: Include everything with a
   *      value
   */
  shouldModelWithoutFieldBeIncluded: function (this: FilterBackboneModel) {
    const isNegative = this.get('isNegative');
    const isEmptyCheckFilter = this.get('value') === null;
    return isNegative ? !isEmptyCheckFilter : isEmptyCheckFilter;
  },
  areAttributesIncluded: function (
    this: FilterBackboneModel,
    obj: ComponentBackboneModel | Reference,
    filterValue: string,
    filterAttributeNames: string[],
    isNegative: boolean
  ) {
    const hasAttributes = filterAttributeNames.every(filterAttributeName =>
      modelHasAttribute(obj, filterAttributeName)
    );
    if (hasAttributes) {
      const values = filterAttributeNames.map(filterAttributeName =>
        obj.get(filterAttributeName)
      );
      const comparator = this.getComparator();
      const isIncluded = comparator.isIncluded(values, this.get('value'));
      return isNegative ? !isIncluded : isIncluded;
    }
    return this.shouldModelWithoutFieldBeIncluded();
  },
  isAttributeIncluded: function (
    this: FilterBackboneModel,
    obj: ComponentBackboneModel | Reference
  ) {
    if (!this.isAffected(obj)) {
      return true;
    }
    const isNegative: boolean = this.get('isNegative');
    const filterAttributeName: string = this.get('name');
    const filterValue: string = this.get('value');
    if (filterAttributeName.includes(',')) {
      return this.areAttributesIncluded(
        obj,
        filterValue,
        filterAttributeName.split(','),
        isNegative
      );
    }

    if (isSpecialAttributeType(filterAttributeName)) {
      const isIncluded = handleSpecialAttributeType(
        filterAttributeName,
        filterValue,
        obj.id
      );

      return isNegative ? !isIncluded : isIncluded;
    }
    const hasAttributeAndField = modelHasAttributeWithField(
      obj,
      filterAttributeName
    );
    const { field } = hasAttributeAndField;
    let { hasAttribute } = hasAttributeAndField;
    let val = obj.get(filterAttributeName);

    if (
      !val &&
      filterAttributeName === ComponentDefaultFilterKeys.INCOMING_REF_COUNT
    ) {
      hasAttribute = true;
      val = (obj as ComponentBackboneModel).getIncomingReferenceCount();
    } else if (
      !val &&
      filterAttributeName === ComponentDefaultFilterKeys.OUTGOING_REF_COUNT
    ) {
      hasAttribute = true;
      val = (obj as ComponentBackboneModel).getOutgoingReferenceCount();
    } else if (
      filterAttributeName === CommonDefaultFilterKeys.TYPE &&
      this.get('affectReference')
    ) {
      val = ((obj as Reference).getRefType() as APIReferenceType).name;
    }

    if (!val) {
      if (field && isFunction(field.get('formatter'))) {
        val = field.get('formatter')(val, obj.attributes);
        hasAttribute = true;
      }
    } else if (field && field.get('defaultValues')) {
      const defaultValues = (
        field.get('defaultValues') as () => { val: string; label: string }[]
      )();
      const newVal = (val as string[])
        .map(v => {
          const valMatch = defaultValues.find(dv => dv.val === v);
          if (valMatch) {
            return valMatch.label;
          }
          return v;
        })
        .join(', ');
      val = newVal;
      hasAttribute = true;
    }

    if (hasAttribute) {
      const fieldType = field?.getType();
      const comparator = this.getComparator();
      if (
        isDateFieldType(fieldType) &&
        dateRangeOperations.fieldNameIsPartOfDateRangeField(
          filterAttributeName
        ) &&
        comparator.id !== FilterInfoOperator.EQUALS
      ) {
        val = dateRangeOperations.replaceEmptyDateRangePartFieldValue({
          fieldName: filterAttributeName,
          fieldType,
          currentFieldValue:
            fieldInterface.getFieldValueForComponentOrReference(
              obj.id,
              filterAttributeName
            ),
          dateRangeFieldCounterpartValue:
            fieldInterface.getFieldValueForComponentOrReference(
              obj.id,
              dateRangeOperations.getDateRangeDateTimeFieldCounterpartName(
                filterAttributeName
              )
            ),
        });
      }

      const resolvedFilterValue =
        filterValue === DynamicFilterValues.COMPONENT_CURRENT
          ? resolveCurrentComponentAttributeFilterValue(filterAttributeName)
          : filterValue;
      const isIncluded = comparator.isIncluded(val, resolvedFilterValue);
      if (isNegative) return !isIncluded;
      return isIncluded;
    }
    return this.shouldModelWithoutFieldBeIncluded();
  },
  isDynamic: function (this: FilterBackboneModel) {
    const { condition, rules, value } = this.attributes;
    if (condition === BooleanOperator.AND || condition === BooleanOperator.OR) {
      return (rules as FilterBackboneModel[]).some(rule => rule.isDynamic());
    }
    return Object.keys(DynamicFilterValues).some(
      key =>
        value === DynamicFilterValues[key as keyof typeof DynamicFilterValues]
    );
  },
  set: function (
    this: FilterBackboneModel,
    key: keyof BackboneFilterAttributes | BackboneFilterAttributes,
    val?: any,
    options?: Backbone.ModelSetOptions
  ) {
    let attrs: BackboneFilterAttributes;
    if (isUndefinedOrNull(key)) {
      return this;
    }
    let actualOptions = options;
    // Handle both `"key", value` and `{key: value}` -style arguments.
    if (typeof key === 'object') {
      attrs = key;
      actualOptions = val;
    } else {
      ((attrs = {}) as BackboneFilterAttributes)[key] = val;
    }
    actualOptions = actualOptions || {};

    const firstPass = isUndefined(this.collection);
    if (firstPass) {
      attrs.comparator =
        attrs.comparator ||
        ((
          AttributeComparators.getDefaultComparator(
            attrs.name || this.get('name')
          ) as AttributeComparator
        ).id as FilterInfoOperator);
      attrs.type = attrs.type || FilterTypes.ATTRIBUTE;
    }

    // Force valid comparator
    const isAttr =
      (!attrs.type && this.get('type') === 'attribute') ||
      attrs.type === 'attribute';
    const comparatorId = attrs.comparator || this.get('comparator');
    const isIsEmptyFilter =
      isAttr && comparatorId === 'equals' && attrs.value === null;
    if (!isIsEmptyFilter && isAttr && attrs.name) {
      const validComparators = AttributeComparators.getAvailableComparators(
        attrs.name
      );
      if (
        !find(validComparators, function (c) {
          return c.id === comparatorId;
        })
      ) {
        // Invalid comparator, default to first valid
        attrs.comparator = validComparators[0].id as FilterInfoOperator;
      }
    }

    Backbone.Model.prototype.set.call(this, attrs, actualOptions);

    return this;
  },
  setRules(this: FilterBackboneModel, rules: FilterBackboneModel[]) {
    Backbone.Model.prototype.set.call(this, { rules }, {});
    return this;
  },
  encodeURI: function (this: FilterBackboneModel) {
    try {
      if (this.isCompLabelFilter() || this.isRefLabelFilter()) {
        return `${JsonFilterPrefixes.LABEL_FORMATTING}${window.encodeURI(
          JSON.stringify(labelFormattingFilterToArray(this.attributes))
        )}&`;
      } else if (this.isWorkspaceFilter()) {
        return `${JsonFilterPrefixes.WORKSPACE_FILTER}${window.encodeURI(
          JSON.stringify(defaultFilterToArray(this.attributes))
        )}&`;
      }
      return `${JsonFilterPrefixes.DEFAULT}${window.encodeURI(
        JSON.stringify(defaultFilterToArray(this.attributes))
      )}&`;
    } catch (error) {
      logError(error as Error, 'Filter.prototype.encodeURI failed');
      return '';
    }
  },
});

export default Filter;
