import Models from 'collections/models';
import { isDateFieldType } from '@ardoq/date-time';
import Backbone from 'backbone';
import * as encodingUtils from '@ardoq/html';
import { listFieldTypes } from 'aqTypes';
import { APIFieldType, ArdoqId } from '@ardoq/api-types';
import { getApiUrl } from 'backboneExtensions';
import type { FieldBackboneModel } from 'aqTypes';
import { OLD_DEFAULT_FIELD_ORDER } from './consts';
import { getCurrentLocale } from '@ardoq/locale';
import { includes, isArray, isFunction, isString, without } from 'lodash';
import { extend, escape } from 'underscore';
import { orgUsersInterface } from 'modelInterface/orgUsers/orgUsersInterface';
import { fieldValueFormatters } from '@ardoq/scope-data';

type FieldClass = FieldBackboneModel & {
  isListType: (type: string) => boolean;
  new (attributes?: Record<string, any>, options?: any): FieldBackboneModel;
};

/** This map is intended to reduce the number of times the array of fields is
 * traversed to find a global field by name. */
export const fieldNameToIdMap = new Map<string, ArdoqId>();

/** Add to the field name to ID cache if the field is persisted and is not
 * already cached. Returns false if field is not yet persisted. */
const cacheFieldName = ({ attributes: { _id, name } }: FieldBackboneModel) => {
  if (!(_id && name)) return false;
  if (!fieldNameToIdMap.has(name)) fieldNameToIdMap.set(name, _id);
  return true;
};

const Field: FieldClass = Backbone.Model.extend(
  {
    idAttribute: '_id',
    urlRoot: `${getApiUrl(Backbone.Model)}/api/field`,
    defaults: {
      _order: OLD_DEFAULT_FIELD_ORDER,
      label: null,
      type: 'Text',
      global: false,
      componentType: [],
      referenceType: [],
      model: null,
    },
    /** @this {FieldBackboneModel} */
    initialize: function () {
      if (typeof this.get('componentType') === 'string') {
        this.set('componentType', [this.get('componentType')]);
      }
      if (!cacheFieldName(this)) this.once('sync', cacheFieldName);
      this.on(
        'change:addList',
        (
          model: FieldBackboneModel,
          value: unknown,
          options: { skipChangeTrigger?: boolean }
        ) => {
          if (options?.skipChangeTrigger) {
            delete this.attributes.addList;
            return;
          }
          if (
            this.attributes.type.match(/list/i) &&
            isArray(this.attributes.addList)
          ) {
            this.attributes.defaultValue = without(
              this.attributes.addList,
              ''
            ).join(',');
          }
          delete this.attributes.addList;
        }
      );
    },
    getCalculatedFieldSettings: function (this: FieldBackboneModel) {
      return this.get('calculatedFieldSettings');
    },
    isCalculated: function (this: FieldBackboneModel) {
      return this.getCalculatedFieldSettings()?.storedQueryId !== undefined;
    },
    isLocallyDerived: function (this: FieldBackboneModel) {
      return this.getCalculatedFieldSettings()?.locallyDerived !== undefined;
    },
    isListType: function (this: FieldBackboneModel) {
      return Field.isListType(this.getType());
    },
    getType: function (this: FieldBackboneModel) {
      return this.get('type');
    },
    removeComponentType: function (this: FieldBackboneModel, typeId: string) {
      this.set('componentType', without(this.get('componentType'), typeId));
    },
    removeReferenceType: function (this: FieldBackboneModel, typeId: string) {
      this.set(
        'referenceType',
        without(this.get('referenceType'), `${typeId}`)
      );
    },
    getComponentTypes: function (this: FieldBackboneModel) {
      return this.get('componentType') || [];
    },
    getReferenceTypes: function (this: FieldBackboneModel) {
      return this.get('referenceType') || [];
    },
    isComponentField: function (this: FieldBackboneModel) {
      return Boolean(
        this.get('plugin') ||
          this.get('global') ||
          this.getComponentTypes().length
      );
    },
    isReferenceField: function (this: FieldBackboneModel) {
      return Boolean(
        this.get('plugin') ||
          this.get('globalref') ||
          this.getReferenceTypes().length
      );
    },
    hasComponentType: function (this: FieldBackboneModel, type: string) {
      if (this.get('plugin') || this.get('global')) {
        return true;
      }
      return includes(this.getComponentTypes(), type);
    },
    hasReferenceType: function (this: FieldBackboneModel, type: string) {
      if (this.get('plugin') || this.get('globalref')) {
        return true;
      }
      return includes(this.getReferenceTypes(), type);
    },
    getModelId: function (this: FieldBackboneModel) {
      return this.get('model');
    },
    getModel: function (this: FieldBackboneModel) {
      const modelId = this.get('model');
      return modelId ? Models.collection.get(modelId) : null;
    },
    name: function (this: FieldBackboneModel) {
      return this.get('name');
    },
    getAllowedValues: function (this: FieldBackboneModel) {
      if (!this.isListType()) {
        return;
      }

      const defaultValue: string = this.get('defaultValue') || '';
      // optimization: this function is called repetitively for every field on every component and reference, even when the field objects are the same.
      // all the string splitting and trimming can be expensive.
      // check the lastAllowedValues property to see if the field's defaultValue is unchanged since the last time this function was called.
      // if the default value hasn't changed, return the already-parsed value that was stored.
      if (this.lastAllowedValues?.[0] === defaultValue) {
        return this.lastAllowedValues[1];
      }
      const result = defaultValue
        .split(',')
        .filter(Boolean)
        .map(value => value.trim());
      this.lastAllowedValues = [defaultValue, result];
      return result;
    },
    getRawLabel: function (this: FieldBackboneModel): string {
      return this.get('label') || this.get('name');
    },
    getLabel: function (this: FieldBackboneModel) {
      return encodingUtils.escapeHTML(this.getRawLabel());
    },
    getRawDescription: function (this: FieldBackboneModel): string {
      return this.get('description') || '';
    },
    getDescription: function (this: FieldBackboneModel) {
      return encodingUtils
        .escapeHTML(this.getRawDescription())
        .replace(/\n/gi, '<br>');
    },
    isFormatHtmlEscaped: function (this: FieldBackboneModel) {
      return (
        [APIFieldType.USER, APIFieldType.TEXT].includes(this.getType()) ||
        this.isListType()
      );
    },

    /**
     * @deprecated Use fieldInterface.format instead.
     */
    format: function (
      this: FieldBackboneModel,
      val: any,
      attributes: any,
      escapeHTML = true,
      excludeTime?: boolean
    ) {
      const locale = getCurrentLocale();
      const fieldType = this.getType();

      if (isFunction(this.get('formatter'))) {
        return this.get('formatter')(val, attributes);
      } else if (
        val === undefined ||
        (!isDateFieldType(fieldType) && val === null) ||
        (isString(val) && val.length === 0)
      ) {
        return '';
      }

      if (fieldType === APIFieldType.DATE_ONLY) {
        return fieldValueFormatters.formatDateTimeFieldValue({
          value: val,
          locale,
          fieldName: this.get('name'),
          excludeTime: true,
        });
      } else if (fieldType === APIFieldType.DATE_TIME) {
        return fieldValueFormatters.formatDateTimeFieldValue({
          value: val,
          locale,
          fieldName: this.get('name'),
          excludeTime,
        });
      } else if (fieldType === APIFieldType.NUMBER) {
        return fieldValueFormatters.formatNumberFieldValue({
          value: val,
          numberFormatOption: this.get('numberFormatOptions'),
          locale,
        });
      } else if (fieldType === APIFieldType.USER) {
        const user = orgUsersInterface.getUserById(val);
        const fallbackLabel = val ? 'User info missing' : '';
        return user
          ? escapeHTML
            ? // Reading through the backbone code, it uses lodash's escape function to escape the user's name or email.
              escape(user.name) || escape(user.email)
            : user.name || user.email
          : fallbackLabel;
      } else if (fieldType === APIFieldType.TEXT_AREA) {
        return fieldValueFormatters.formatTextAreaFieldValue(val, escapeHTML);
      } else if (
        isArray(val) &&
        (fieldType === APIFieldType.SELECT_MULTIPLE_LIST ||
          // @ts-expect-error legacy field type
          fieldType === 'SelectMultiple')
      ) {
        /** default field values. not supported in Ardoq for a Select Multiple field, but this code is from a commit related to Jira View, so it may be supported there. */
        const defaultValues:
          | (() => { val: string; label: string }[])
          | undefined = this.get('defaultValues');
        if (defaultValues) {
          const result = val
            .map(v => {
              const valMatch = defaultValues().find(dv => dv.val === v);
              if (valMatch) {
                return valMatch.label;
              }
              return v;
            })
            .join(', ');
          return escapeHTML ? encodingUtils.escapeHTML(result) : result;
        }
        const result =
          fieldValueFormatters.formatSelectMultipleListFieldValue(val);
        return escapeHTML ? encodingUtils.escapeHTML(result) : result;
      } else if (fieldType === APIFieldType.DATE_ONLY_RANGE) {
        return fieldValueFormatters.formatDateOnlyRangeFieldValue(val, locale);
      } else if (fieldType === APIFieldType.DATE_TIME_RANGE) {
        return fieldValueFormatters.formatDateTimeRangeFieldValue(val, locale);
      }
      return escapeHTML ? encodingUtils.escapeHTML(val) : val;
    },

    destroy: function (this: FieldBackboneModel, options = {}) {
      const opts = extend({ processData: true }, options);
      const { name, _id } = this.attributes;
      if (fieldNameToIdMap.get(name) === _id) fieldNameToIdMap.delete(name);
      return Backbone.Model.prototype.destroy.call(this, opts);
    },
  },
  {
    isListType: (type: APIFieldType) => listFieldTypes.includes(type),
  }
);
function createField(attributes: Record<string, any>, options?: any) {
  return new Field(attributes, options);
}

export default {
  model: Field,
  create: createField,
};
