import { ViewIds } from '@ardoq/api-types';
import { clone, get, set } from 'lodash';

export const composeNewSettings = <T>(
  settings: T,
  changes: Partial<T>,
  path?: string[]
) => {
  if (!path) {
    return { ...settings, ...changes } as T;
  }

  const newSettings = { ...settings };
  // Navigate to the path to update, cloning along the way so we don't mutate
  // the original `settings`
  const subSettings = path.reduce<any>((cursor, pathPart) => {
    const currentSubSettings = clone(get(cursor, pathPart));
    set(cursor, pathPart, currentSubSettings);
    return currentSubSettings;
  }, newSettings);

  Object.assign(subSettings, changes);

  return newSettings;
};

type ExtractChangedSettingsArgs = {
  settingsPath?: string[];
  nextSettings: Record<string, any>;
  settings: Record<string, any> & {
    fullScreen?: boolean | undefined;
  };
};
export const extractChangedSettings = ({
  settingsPath,
  nextSettings,
  settings,
}: ExtractChangedSettingsArgs): [string, any][] =>
  settingsPath?.length
    ? [[settingsPath[0], nextSettings[settingsPath[0]]]]
    : Object.entries(settings);

export const throwDefaultViewStateError = (viewId: ViewIds) => {
  throw new Error(
    `No defaultstate found for ${viewId}, please add to views/defaultState.js`
  );
};
