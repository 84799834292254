import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { usersOperations } from '@ardoq/core';

/**
 * Get all members of the organization including contributors.
 */
const getAllOrgUsers = () =>
  usersOperations.getAllOrgUsers(orgUsers$.state.users);

/**
 * Get all members of the organization except contributors.
 */
const getOrgMembers = () => usersOperations.getOrgMembers(getAllOrgUsers());

const getOrgAdmins = () => usersOperations.getOrgAdmins(getAllOrgUsers());

const getOrgContributors = () =>
  usersOperations.getOrgContributors(getAllOrgUsers());

const getUserById = (userId: string) =>
  usersOperations.getUserById(orgUsers$.state.byId, userId);

export const orgUsersInterface = {
  /**
   * Get all members of the organization including contributors.
   */
  getAllOrgUsers,
  /**
   * Get all members of the organization except contributors.
   */
  getOrgMembers,
  getOrgContributors,
  getUserById,
  getOrgAdmins,
};
