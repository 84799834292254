import { catchErrorLogAndContinue } from './streamOperators';
import { Observable } from 'rxjs';
import { tag } from 'rxjs-spy/operators';
import { shareReplay } from 'rxjs/operators';

type StateStreamOptions = {
  debugName: string;
  isTemporary: boolean;
};

type CreateStateStream<StateShape> = StateStreamOptions & {
  createStream(...streams: Observable<any>[]): Observable<StateShape>;
};

/**
 *
 * Low-level API for creating a state stream
 *
 * This allows you to provide a createStream method
 * that takes an arbitrary number of streams and returns a stream creator
 *
 * In the createStream method you must ensure that your stream starts with
 * a value, either using startWith or with the RESET_ACTION pattern.
 *
 * isTemporary governs whether the stream is refCounted.
 *    If true:  The stream will "complete" when there are no more subscribers.
 *              After "completion", if there are new subscribers, the stream
 *              will restart from scratch.
 *
 *    If false: The stream will "live on forever" and never complete.
 *              This means that the stream won't reset to its default state
 *              when there are no more subscribers. New subscribers
 *              will always see the last calculation of state.
 *
 *    Typically, view-specific state streams should have this set to true,
 *    if we want them to reset their state when the view remounts.
 *    Global application state streams should have this to false,
 *    since we want the state to persist even when there are no subscribers.
 *
 *    One example of a global application state stream is the searchView$:
 *      - We want the query builder selection state to persist even
 *        after the view is unmounted (because you might open a different module).
 *
 *
 *
 */
export function createStateStream<StateShape>({
  debugName,
  isTemporary = true,
  createStream,
}: CreateStateStream<StateShape>) {
  return (...streams: Observable<any>[]) => {
    const stream = createStream(...streams).pipe(
      catchErrorLogAndContinue(),
      shareReplay({ bufferSize: 1, refCount: isTemporary }),
      tag(`state$-${debugName}`)
    );
    return stream;
  };
}
